import type { RestaurantDetails } from './apiHelpers';
import styles from './SubNav.scss';
import { SubNavLink } from './SubNavLink';

interface SubNavProps {
  restaurant: RestaurantDetails;
}

export const SubNav = ({ restaurant }: SubNavProps) => {
  const { locationSlug, nameSlug } = restaurant;
  const seatingPath = `/${locationSlug}/${nameSlug}`;
  const aboutPath = `/${locationSlug}/${nameSlug}/about`;

  return (
    <div className={styles.subNav}>
      <SubNavLink to={seatingPath}>Seating</SubNavLink>
      <SubNavLink to={aboutPath}>About</SubNavLink>
    </div>
  );
};
