import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import { useTimer } from '../hooks/useTimer';
import { AcceptTopOfferModal } from '../offers/AcceptTopOfferModal';
import { sendGa4OfferAcceptStartEvent } from '../offers/gaHelpers';
import styles from './AcceptTopOffer.scss';
import type { ReservationWithDetails, TopOffer } from './apiHelpers';

export interface AcceptTopOfferProps {
  offer?: TopOffer;
  reservation: ReservationWithDetails;
}

export const AcceptTopOffer = ({ offer, reservation }: AcceptTopOfferProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const timeRemaining = useTimer(offer);

  const MINIMUM_OFFER_TIME_REMAINING = 1;

  return (
    <>
      <div className={styles.acceptTopOffer}>
        <h3 className={cx(typography.h7m, styles.acceptTopOfferHeader)}>
          Accept Top Offer
        </h3>
        {!offer || !Object.keys(offer).length ? (
          <div className={cx(typography.h8, styles.noOfferPill)}>
            No offers yet
          </div>
        ) : (
          <>
            <Button
              className={styles.sellNowButton}
              isDisabled={
                timeRemaining !== undefined &&
                timeRemaining < MINIMUM_OFFER_TIME_REMAINING
              }
              label={`Sell now for ${centsToWholeDollar(offer.price)}`}
              onClick={() => {
                sendGa4OfferAcceptStartEvent({
                  date: reservation.date,
                  offerAmount: offer.price,
                  publicName: reservation.listing.publicName,
                  restaurantName: reservation.restaurant.name,
                  time: reservation.time,
                });
                openModal();
              }}
              variant={ButtonVariants.Primary}
            />
            {timeRemaining && (
              <div className={styles.expiration}>
                <div className={cx(typography.t3, styles.expirationLabel)}>
                  expiring
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {offer && (
        <AcceptTopOfferModal
          closeModal={closeModal}
          isOpen={isModalOpen}
          offer={offer}
          reservation={reservation}
        />
      )}
    </>
  );
};
