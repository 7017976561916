import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';
import type { TopOffer } from 'webReservations/reservationDetails/apiHelpers';

const INTERVAL_IN_MS = 250;
export const useTimer = (offer: TopOffer | undefined) => {
  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    if (offer) {
      const interval = setInterval(() => {
        setTimer(differenceInSeconds(new Date(offer.expiredAt), Date.now()));
      }, INTERVAL_IN_MS);

      return () => clearInterval(interval);
    }
    setTimer(undefined);
    return undefined;
  }, [offer]);

  return timer;
};
