// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantAboutLink__linkText___u7q6H{align-items:center;border-radius:8px;border:1px solid var(--hairline, #404c60);color:var(--white70);display:flex;margin-bottom:8px;max-width:624px;padding:16px;width:100%}.RestaurantAboutLink__linkText___u7q6H .RestaurantAboutLink__icon___zXNQX{width:16px;height:16px;margin-right:16px;min-width:16px;stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/RestaurantAboutLink.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CACA,iBAAA,CACA,yCAAA,CACA,oBAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CAEA,0EACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkText": "RestaurantAboutLink__linkText___u7q6H",
	"icon": "RestaurantAboutLink__icon___zXNQX"
};
export default ___CSS_LOADER_EXPORT___;
