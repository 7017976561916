import type { LoaderFunctionArgs } from 'react-router-dom';
import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import {
  getRestaurantDetailsBySlug,
  type RestaurantDetails,
} from '../restaurant/apiHelpers';

export const restaurantLoader = async ({
  params: { locationSlug = '', nameSlug = '' },
}: LoaderFunctionArgs<{
  params: { locationSlug: string; nameSlug: string };
}>): Promise<RestaurantDetails> => {
  const restaurantResponse = await getRestaurantDetailsBySlug(
    locationSlug,
    nameSlug,
  );

  if (isErrorResponse(restaurantResponse)) {
    throw createResponseError(restaurantResponse);
  }

  return restaurantResponse;
};
