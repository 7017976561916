import cx from 'classnames';
import type { ReactNode } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import styles from './Unauthenticated.scss';

export interface UnauthenticatedProps {
  className?: string;
  subtitle: ReactNode;
  title: string;
}

export const Unauthenticated = ({
  className,
  subtitle,
  title,
}: UnauthenticatedProps) => {
  const { openModal } = useAuthModalContext();

  return (
    <main className={cx(styles.container, className)}>
      <h1 className={cx(typography.d3, styles.title)}>{title}</h1>
      {subtitle && (
        <p className={cx(typography.c2, styles.subtitle)}>{subtitle}</p>
      )}
      <Button
        className={styles.loginButton}
        label="Login"
        onClick={() => openModal(AuthModalTypes.SIGN_IN)}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Primary}
      />
      <Button
        className={styles.signUpButton}
        label="Sign Up"
        onClick={() => openModal(AuthModalTypes.SIGN_UP)}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Default}
      />
    </main>
  );
};
