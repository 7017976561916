import typography from '~styles/typography.scss';
import styles from './InfoTabContentNarrow.scss';
import { FOR_DINERS_CONTENT, FOR_RESTAURANT_CONTENT, Tab } from './utils';

interface InfoTabContentProps {
  activeTab: Tab;
}

export const InfoTabContentNarrow = ({ activeTab }: InfoTabContentProps) => {
  const content =
    activeTab === Tab.ForRestaurants
      ? FOR_RESTAURANT_CONTENT
      : FOR_DINERS_CONTENT;

  return content.map(({ title, description, assetUrl }) => (
    <div key={title} className={styles.slide}>
      <h4 className={typography.d4}>{title}</h4>
      <p>{description}</p>
      <img alt={title} loading="lazy" src={assetUrl} />
    </div>
  ));
};
