import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FeatureFlagContextProvider } from '@shared/context/FeatureFlagContext';
import { AuthModalContextProvider } from './context/AuthModalContext';
import { AvailabilityContextProvider } from './context/AvailabilityContext';
import { DrawerContextProvider } from './context/DrawerContext';
import { OfferCheckoutContextProvider } from './context/OfferCheckoutContext';
import { PlaidContextProvider } from './context/PlaidContext';
import { RestaurantContextProvider } from './context/RestaurantContext';
import { UserContextProvider } from './context/UserContext';
import { ScrollToTop } from './components/ScrollToTop';
import { featureFlagConfig } from './featureFlags';
import { AuthModalsContainer } from './modal/AuthModalsContainer';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.overrides.css';
import '../index.scss';

export const Root = () => (
  <FeatureFlagContextProvider environmentConfig={featureFlagConfig}>
    <UserContextProvider>
      <RestaurantContextProvider>
        <AvailabilityContextProvider>
          <OfferCheckoutContextProvider>
            <AuthModalContextProvider>
              <DrawerContextProvider>
                <PlaidContextProvider>
                  <Outlet />
                  <ScrollToTop />
                  <ToastContainer
                    position="top-right"
                    autoClose={false}
                    newestOnTop
                    limit={1}
                    closeButton
                    hideProgressBar
                    pauseOnHover
                    theme="colored"
                  />
                </PlaidContextProvider>
              </DrawerContextProvider>
              <AuthModalsContainer />
            </AuthModalContextProvider>
          </OfferCheckoutContextProvider>
        </AvailabilityContextProvider>
      </RestaurantContextProvider>
    </UserContextProvider>
  </FeatureFlagContextProvider>
);
