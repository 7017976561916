import cx from 'classnames';
import { useRouteLoaderData } from 'react-router-dom';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import typography from '~styles/typography.scss';
import { RestaurantHero } from '../components/RestaurantHero';
import { DeprecatedLayout } from '../layout/DeprecatedLayout';
import type { RestaurantDetails } from './apiHelpers';
import { RestaurantAboutLink } from './RestaurantAboutLink';
import aboutStyles from './RestaurantAboutPage.scss';
import restaurantStyles from './RestaurantPage.scss';
import { SubNav } from './SubNav';

export const RestaurantAboutPage = () => {
  const restaurant = useRouteLoaderData('restaurant') as RestaurantDetails;

  useDocumentTitle(`${restaurant.name} Home | Peak Reservations`);

  return (
    <DeprecatedLayout hideBorder className={restaurantStyles.layout}>
      <main className={restaurantStyles.container}>
        <RestaurantHero
          restaurant={restaurant}
          navBar={<SubNav restaurant={restaurant} />}
        />
        <div className={restaurantStyles.content}>
          <div className={aboutStyles.body}>
            <p className={cx(typography.c3, aboutStyles.description)}>
              {restaurant.description}
            </p>
            <RestaurantAboutLink to={restaurant.website} iconName="browser">
              Go to website
            </RestaurantAboutLink>
            <RestaurantAboutLink
              to={`tel:${restaurant.phone}`}
              iconName="phone"
            >
              {restaurant.phone}
            </RestaurantAboutLink>
            <RestaurantAboutLink
              iconName="locationArrow"
              to={`http://maps.google.com/?q=${restaurant.address}`}
            >
              {restaurant.address}
            </RestaurantAboutLink>
          </div>
        </div>
      </main>
    </DeprecatedLayout>
  );
};
