import cx from 'classnames';
import type { FieldError, FieldValues, Path } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ValidationErrorMessage.scss';

interface ValidationErrorMessageProps<T extends FieldValues> {
  error: FieldError | undefined;
  label: string;
  name: Path<T>;
}
export const ValidationErrorMessage = <T extends FieldValues>({
  error,
  label,
  name,
}: ValidationErrorMessageProps<T>) => {
  if (!error) {
    return null;
  }

  const errorMessage = error && (error.message || `${label} ${error.type}`);

  return (
    <div
      className={cx(typography.t2, styles.errorMessage)}
      id={`${name}-error`}
    >
      {errorMessage}
    </div>
  );
};
