// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferCheckoutLayout__layout___r_kfY{background-color:var(--primary400)}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/OfferCheckoutLayout.scss"],"names":[],"mappings":"AAAA,qCACE,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "OfferCheckoutLayout__layout___r_kfY"
};
export default ___CSS_LOADER_EXPORT___;
