import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

interface DeletePaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeletePaymentMethodModal = ({
  isOpen,
  onClose,
  onConfirm,
}: DeletePaymentMethodModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title="Remove Payment Method"
    subtitle="Click confirm to remove this payment method from your list of payment options. Removing this payment method will not affect any existing reservations, offers, or potential cancellation fees."
  >
    <ModalActions>
      <Button
        label="Cancel"
        onClick={onClose}
        variant={ButtonVariants.Secondary}
      />
      <Button
        label="Confirm"
        onClick={onConfirm}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
