import cx from 'classnames';
import { type ReactNode } from 'react';
import styles from './ModalActions.scss';

interface ModalActionsProps {
  children: ReactNode;
  className?: string;
  stack?: boolean;
}

export const ModalActions = ({
  children,
  className,
  stack = false,
}: ModalActionsProps) => (
  <div className={cx(styles.container, { [styles.stack]: stack }, className)}>
    {children}
  </div>
);
