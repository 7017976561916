import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './InfoTabContentWide.scss';
import { FOR_DINERS_CONTENT, FOR_RESTAURANT_CONTENT, Tab } from './utils';

interface InfoTabContentProps {
  activeTab: Tab;
  activeSlideIndex: number;
  setActiveSlideIndex: (index: number) => void;
}

export const InfoTabContentWide = ({
  activeTab,
  activeSlideIndex,
  setActiveSlideIndex,
}: InfoTabContentProps) => {
  const content =
    activeTab === Tab.ForRestaurants
      ? FOR_RESTAURANT_CONTENT
      : FOR_DINERS_CONTENT;

  const activeSlide = content[activeSlideIndex];

  return (
    <div className={styles.slide}>
      <div>
        {content.map(({ title, description }, index) => (
          <button
            key={title}
            className={cx({ [styles.active]: activeSlideIndex === index })}
            onClick={() => setActiveSlideIndex(index)}
          >
            <h4 className={typography.d4}>{title}</h4>
            <p>{description}</p>
          </button>
        ))}
      </div>
      <div>
        <img
          alt={activeSlide.title}
          loading="lazy"
          src={activeSlide.assetUrl}
        />
      </div>
    </div>
  );
};
