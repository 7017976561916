// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisconnectBankAccountModal__container___OatDm p{color:var(--white70);margin:0 auto;max-width:30em;text-wrap:pretty}@media only screen and (min-width: 768px){.DisconnectBankAccountModal__container___OatDm p{text-align:center}}.DisconnectBankAccountModal__container___OatDm p+p{margin-top:1.5rem}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/DisconnectBankAccountModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAGE,iDACE,oBAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CCyBF,0CD7BA,iDAOI,iBAAA,CAAA,CAIJ,mDACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DisconnectBankAccountModal__container___OatDm"
};
export default ___CSS_LOADER_EXPORT___;
