import { useEffect, useState } from 'react';
import { getFloorPlanSnapshotForReservationId } from '@components/floorPlan/apiHelpers';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import type { FloorPlanSnapshotData } from '@shared/types/floorPlans';

interface SnapshotFloorPlanProps {
  disableControls?: boolean;
  reservationId?: string;
}

export const SnapshotFloorPlan = ({
  disableControls = false,
  reservationId,
}: SnapshotFloorPlanProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [floorPlanSnapshot, setFloorPlanSnapshot] =
    useState<FloorPlanSnapshotData>();

  useEffect(() => {
    if (!reservationId) return;
    const fetchFloorPlanSnapshot = async (): Promise<void> => {
      const response =
        await getFloorPlanSnapshotForReservationId(reservationId);
      if (isSuccessResponse(response)) {
        setFloorPlanSnapshot(response);
      }
      setIsLoading(false);
    };

    void fetchFloorPlanSnapshot();
  }, [reservationId]);

  if (isLoading && !floorPlanSnapshot) {
    return null;
  }

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    floorPlanTablesRendererFactory({
      calculateIsHighlighted: (floorPlanTable) => floorPlanTable.isHighlighted,
      tables: floorPlanSnapshot?.floorPlanTables || [],
    });

  return (
    <FloorPlan
      backgroundSrc={floorPlanSnapshot?.backgroundSrc}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
      disableControls={disableControls}
    />
  );
};
