import { useEffect, useState } from 'react';

const MIN_FLOOR_PLAN_DIMENSION = 25;

export interface FloorPlanBackgroundImgProps {
  backgroundSrc?: string;
  handleBackgroundOnClick?: () => void;
  height: number;
  initialHeight: number;
  initialWidth: number;
  setTableIconScale: (scale: number) => void;
  width: number;
}

export const FloorPlanBackgroundImg = ({
  backgroundSrc,
  height,
  width,
  setTableIconScale,
  initialHeight,
  initialWidth,
}: FloorPlanBackgroundImgProps) => {
  const [smallestDimension, setSmallestDimension] = useState({});
  const areFloorPlanImageAndContainerReady =
    initialHeight &&
    height > MIN_FLOOR_PLAN_DIMENSION &&
    width > MIN_FLOOR_PLAN_DIMENSION;

  useEffect(() => {
    if (areFloorPlanImageAndContainerReady) {
      const isTallImage = initialHeight > initialWidth;
      setSmallestDimension(isTallImage ? { height } : { width });
      setTableIconScale(
        isTallImage ? height / initialHeight : width / initialWidth,
      );
    }
  }, [height, width, initialHeight]);

  if (!initialHeight) return null;

  return (
    <img
      {...smallestDimension}
      alt="restaurant floor plan"
      src={backgroundSrc}
    />
  );
};
