import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

export interface Restaurant {
  id: string;
  name: string;
  description: string;
  address: string;
  phone: string;
  website: string;
}

export enum ApiStatus {
  Active = 'active',
  Canceled = 'canceled',
  Finished = 'finished',
  NoShow = 'noShow',
  Resold = 'resold',
  AutoResolved = 'autoResolved',
}

export interface Reservation {
  buyNowPrice: number | null;
  date: string;
  guestCount: number;
  id: string;
  restaurant: Restaurant;
  status?: ApiStatus;
  listing: {
    publicName: string;
  };
  time: string;
}

export const clearListPrice = async (reservationId: string) => {
  const response = await API.post(
    `/reservations/${reservationId}/clear-buy-now`,
  );
  return response.ok;
};

export const getConfirmedReservations = async (): Promise<
  ApiResponse<Reservation[]>
> => {
  const response = await API.get(`/reservations/confirmed`);
  return response.json();
};

export const getInactiveReservations = async (): Promise<
  ApiResponse<Reservation[]>
> => {
  const response = await API.get('/reservations/inactive');
  return response.json();
};

export const updateListPrice = async (
  reservationId: string,
  price: number,
): Promise<boolean> => {
  const response = await API.post(
    `/reservations/${reservationId}/set-buy-now`,
    { price },
  );
  return response.ok;
};
