import { useEffect, useState } from 'react';

const DEFAULT_INITIAL_DIMENSION = 0;

export const useLoadImage = (src: string) => {
  const [initialImageDimensions, setInitialImageDimensions] = useState({
    initialHeight: DEFAULT_INITIAL_DIMENSION,
    initialWidth: DEFAULT_INITIAL_DIMENSION,
    isLoaded: false,
  });

  useEffect(() => {
    const img = new Image();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    img.onload = (e: any) => {
      setInitialImageDimensions({
        initialHeight: e.target.naturalHeight,
        initialWidth: e.target.naturalWidth,
        isLoaded: true,
      });
    };
    img.src = src;
  }, [src]);

  return initialImageDimensions;
};
