/* eslint-disable max-classes-per-file */
import { Api, type ApiGetOptions } from '@shared/api';
import { ApiUnauthorizedError } from '@shared/api/errors';

interface AdminApiGetOptions extends ApiGetOptions {
  redirectUnauthorized?: boolean;
}

class AdminApi extends Api {
  async get<T>(path: string, options: AdminApiGetOptions = {}): Promise<T> {
    const { redirectUnauthorized = true, ...superOptions } = options;
    try {
      return await super.get(path, superOptions);
    } catch (e) {
      if (redirectUnauthorized && e instanceof ApiUnauthorizedError) {
        // It might be better to use react-router here, but this works well
        // enough for now.
        // The guest app has page-specific handing of unauthorized errors.
        // Only do this for GET requests because redirecting on other requests
        // could be more disruptive.
        window.location.assign('/');
        throw new RedirectOnApiUnauthorizedError(
          'redirecting to / after a GET request returned an unauthorized error',
          { cause: e },
        );
      }
      throw e;
    }
  }
}

export const api = new AdminApi();

// Thrown when a redirect is in progress; This exists so that we can avoid
// reporting to Sentry in this scenario
export class RedirectOnApiUnauthorizedError extends Error {}
