import { parsePhoneNumberWithError } from 'libphonenumber-js';

export const formatPhone = (phone: string | null): string | null => {
  if (!phone) return phone;

  try {
    return parsePhoneNumberWithError(phone, 'US').formatInternational();
  } catch {
    return phone;
  }
};
