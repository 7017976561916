import type { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IPAD810 } from '@shared/styles/breakpoints';
import { ReservationAttributesGrid } from 'webReservations/reservations/ReservationAttributesGrid';
import { ReservationAttributesInline } from 'webReservations/reservations/ReservationAttributesInline';
import { ReservationStatus } from 'webReservations/reservations/ReservationStatus';
import { RestaurantHero } from '../components/RestaurantHero';
import { DeprecatedLayout } from '../layout/DeprecatedLayout';
import type * as statusHelpers from '../reservations/statusHelpers';
import styles from './DetailsPageView.scss';

interface DetailsViewProps {
  children: ReactNode;
  floorPlanComponent: ReactNode;
  status?: statusHelpers.ReservationStatusType;
  restaurant: {
    address: string;
    id: string;
    name: string;
  };
  date: string;
  guestCount: number;
  listingPublicName: string;
  time: string;
}

export const DetailsPageView = ({
  children,
  floorPlanComponent,
  status,
  restaurant,
  date,
  guestCount,
  listingPublicName,
  time,
}: DetailsViewProps) => {
  const isNarrowViewport = useMediaQuery({ maxWidth: IPAD810 });

  return (
    <DeprecatedLayout>
      <main className={styles.main}>
        <RestaurantHero restaurant={restaurant}>
          {status && <ReservationStatus status={status} />}
        </RestaurantHero>
        <div className={styles.content}>
          <div className={styles.leftPanel}>
            {isNarrowViewport ? (
              <ReservationAttributesGrid
                containerClassName={styles.reservationAttributesContainer}
                date={date}
                guestCount={guestCount}
                publicName={listingPublicName}
                time={time}
              />
            ) : (
              <ReservationAttributesInline
                containerClassName={styles.reservationAttributesContainer}
                date={date}
                guestCount={guestCount}
                listingPublicName={listingPublicName}
                time={time}
              />
            )}
            <div className={styles.floorPlanContainer}>
              {floorPlanComponent}
            </div>
          </div>
          <div className={styles.rightPanel}>{children}</div>
        </div>
      </main>
    </DeprecatedLayout>
  );
};
