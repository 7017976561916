import cx from 'classnames';
import type { ReactNode } from 'react';
import typography from '~styles/typography.scss';
import styles from './EmptyState.scss';

interface EmptyStateProps {
  children?: ReactNode;
  subtitle: string;
  title: string;
}

export const EmptyState = ({ children, subtitle, title }: EmptyStateProps) => (
  <div className={styles.container}>
    <h1 className={cx(typography.d3, styles.title)}>{title}</h1>
    <h2 className={cx(typography.c2, styles.subtitle)}>{subtitle}</h2>
    {children}
  </div>
);
