import typography from '~styles/typography.scss';
import styles from './Partners.scss';
import { RestaurantCard } from './RestaurantCard';

export const Partners = () => (
  <section className={styles.section}>
    <h2 className={typography.d3}>A Glimpse Into Our Partner Restaurants</h2>
    <div>
      <RestaurantCard
        address="4200 E Palm Canyon Dr, Palm Springs, CA 92264"
        id="3ab4f295-01f8-4310-889d-8726b7a8563c"
        locationSlug="palm-springs"
        name="Norma's"
        nameSlug="normas"
      />
      <RestaurantCard
        address="4200 E Palm Canyon Dr, Palm Springs, CA 92264"
        id="3f0b961b-5a3d-4e68-98b1-29b881fd338c"
        locationSlug="palm-springs"
        name="mister parker's"
        nameSlug="mister-parkers"
      />
    </div>
  </section>
);
