import { kebabCase } from 'lodash-es';
import { Tooltip } from 'react-tooltip';
import { Icon } from '@components/icon/Icon';
import styles from './FormTooltip.scss';

export interface FormTooltipProps {
  label: string;
  text: string | undefined;
}

export const FormTooltip = ({ label, text }: FormTooltipProps) => {
  if (!text) return null;

  const labelKebab = kebabCase(label);

  return (
    <>
      <button
        aria-describedby={`${labelKebab}-popup`}
        aria-label={`${label} information`}
        className={styles.button}
        id={`${labelKebab}-tooltip`}
        onClick={() => undefined}
        type="button"
      >
        <Icon className={styles.questionIcon} name="questionMark" />
      </button>
      <Tooltip
        className={styles.tooltip}
        noArrow
        place="bottom"
        anchorSelect={`#${labelKebab}-tooltip`}
        render={() => (
          <>
            <Icon className={styles.questionIcon} name="questionMark" />
            <div id={`${labelKebab}-popup`}>{text}</div>
          </>
        )}
        style={{ borderRadius: '8px', padding: 0 }}
        globalCloseEvents={{
          scroll: true,
          resize: true,
          escape: true,
          clickOutsideAnchor: true,
        }}
      />
    </>
  );
};
