import type { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE } from '@shared/styles/breakpoints';

type ComponentOrNull = ReactElement | null;

/**
 * @deprecated This component switches at a buggy breakpoint. Use `WideView` instead.
 */
export const DeprecatedNarrowView = ({
  element = null,
  fallback = null,
}: {
  element?: ComponentOrNull;
  fallback?: ComponentOrNull;
}): ComponentOrNull => {
  const isNarrowViewport = useMediaQuery({ maxWidth: MOBILE });

  return isNarrowViewport ? element : fallback;
};
