import { isErrorResponse } from '@shared/types/apiHelpers';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import { useUserContext } from '../context/UserContext';
import { login } from './apiHelpers';
import { VerifyOTPModal } from './VerifyOTPModal';

interface SignInOTPModalProps {
  phone: string;
  onAuthSuccess: () => void;
}

export const SignInOTPModal = ({
  onAuthSuccess,
  phone,
}: SignInOTPModalProps) => {
  const { setUser, setIsAuthenticated } = useUserContext();
  const { openModal, closeModal, nextModal } = useAuthModalContext();

  const onClickCreateAccount = () => {
    openModal(AuthModalTypes.SIGN_UP, { onAuthSuccess });
  };

  const onSubmit = async (phoneVerificationCode: string) => {
    const response = await login({
      phone,
      phoneVerificationCode,
    });

    if (isErrorResponse(response)) return response;

    if (response.isRegistered) {
      closeModal();
      setIsAuthenticated(true);
      setUser(response);
      onAuthSuccess();
    } else {
      nextModal(AuthModalTypes.UNREGISTERED_USER_LOGIN, {
        onAuthSuccess,
      });
    }

    return response;
  };

  return (
    <VerifyOTPModal
      label="Phone Number Verification"
      onClickCreateAccount={onClickCreateAccount}
      onSubmit={onSubmit}
      phone={phone}
      submitButtonLabel="Login"
    />
  );
};
