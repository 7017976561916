// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout__container____l5O7{display:flex;flex-direction:column;justify-content:space-between;min-height:100vh;position:relative}.Layout__mainContent____3zft{display:inline-block;margin-top:var(--headerHeight);width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/layout/Layout.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,gBAAA,CACA,iBAAA,CAGF,6BACE,oBAAA,CACA,8BAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Layout__container____l5O7",
	"mainContent": "Layout__mainContent____3zft"
};
export default ___CSS_LOADER_EXPORT___;
