import { useEffect } from 'react';

export const useOverlay = (isModalVisible: boolean) => {
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalVisible]);
};
