import { CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import type {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
} from '@stripe/stripe-js';
import cx from 'classnames';
import { useState } from 'react';
import styles from './CardElement.scss';

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      color: '#000',
      fontFamily: '-apple-system, BlinkMacSystemFont, Inter, sans-serif',
      fontSize: '14px',
      iconColor: '#364153',
      '::placeholder': {
        color: '#677489',
      },
    },
    invalid: {
      iconColor: '#b14a53',
      color: '#b14a53',
    },
    complete: {
      iconColor: '#84b14a',
    },
  },
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: StripeCardElementChangeEvent) => any;
}

export const CardElement = ({ onChange }: Props) => {
  const [cardElementHasFocus, setCardElementHasFocus] = useState<boolean>();

  const cardElementStyle = cardElementHasFocus
    ? cx(styles.cardElement, styles.cardElementFocused)
    : styles.cardElement;

  return (
    <div className={cardElementStyle}>
      <StripeCardElement
        options={CARD_ELEMENT_OPTIONS}
        onBlur={() => setCardElementHasFocus(false)}
        onChange={onChange}
        onFocus={() => setCardElementHasFocus(true)}
      />
    </div>
  );
};
