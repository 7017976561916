import { Input } from '@mui/base/Input';
import cx from 'classnames';
import type {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormCurrencyInput.scss';
import { FormTooltip } from './FormTooltip';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormCurrencyInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  variant?: LayoutVariant;
}

export const ControlledFormCurrencyInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  defaultValue,
  disabled,
  label,
  name,
  placeholder,
  rules = {},
  tooltipText,
  variant = LayoutVariant.Vertical,
}: ControlledFormCurrencyInputProps<T, Name>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
  });

  const showCurrency = value !== undefined && value !== '';
  const currencyAdornment = (
    <span
      className={styles.currency}
      style={{ visibility: showCurrency ? undefined : 'hidden' }}
    >
      $
    </span>
  );

  return (
    <div className={cx(styles.fieldContainer, styles[variant], className)}>
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.inputContainer}>
        <Input
          autoComplete="off"
          disabled={disabled}
          id={name}
          onChange={onChange}
          onWheel={() => (document.activeElement as HTMLInputElement).blur()}
          placeholder={placeholder}
          slotProps={{
            root: {
              className: cx(typography.t1, styles.inputRoot, {
                [styles.inputError]: !!error,
              }),
            },
            input: {
              'aria-errormessage': `${name}-error`,
              'aria-invalid': !!error,
            },
          }}
          startAdornment={currencyAdornment}
          type="number"
          value={value || ''}
        />
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};
