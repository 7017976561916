// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurrencyInput__input___M2cWc{display:block;padding:8px 16px;height:40px;width:100%;border:2px solid var(--darkGrey300);border-radius:4px;background-color:var(--primary500);color:var(--white);text-align:right}.CurrencyInput__input___M2cWc:focus{outline:var(--primary100) auto medium}", "",{"version":3,"sources":["webpack://./src/shared/components/currencyInput/CurrencyInput.scss"],"names":[],"mappings":"AAAA,8BACE,aAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CACA,mCAAA,CACA,iBAAA,CACA,kCAAA,CACA,kBAAA,CACA,gBAAA,CAEA,oCACE,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "CurrencyInput__input___M2cWc"
};
export default ___CSS_LOADER_EXPORT___;
