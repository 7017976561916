import type { LoaderFunctionArgs } from 'react-router-dom';
import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { getOffer } from '../offers/apiHelpers';

export const offerLoader = async ({
  params: { offerId = '' },
}: LoaderFunctionArgs<{
  params: { offerId: string };
}>) => {
  const response = await getOffer(offerId);

  if (isErrorResponse(response)) {
    throw createResponseError(response);
  }

  return response;
};
