// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SetListPriceModal__container___m5Sf9{align-items:center;display:flex;flex-direction:column;gap:2rem;max-width:35rem}.SetListPriceModal__container___m5Sf9 form{border-top:1px solid var(--hairline);padding:1.5rem 0 0;width:100%}@media only screen and (min-width: 768px){.SetListPriceModal__container___m5Sf9 form{border:1px solid var(--hairline);border-radius:.25rem;margin-top:2rem;padding:3rem}}.SetListPriceModal__container___m5Sf9 button{width:100%}.SetListPriceModal__container___m5Sf9 .SetListPriceModal__actions___xt7dS{margin-top:2rem;width:100%}@media only screen and (min-width: 768px){.SetListPriceModal__container___m5Sf9 .SetListPriceModal__actions___xt7dS{margin-top:4rem}}.SetListPriceModal__container___m5Sf9 .SetListPriceModal__error___tuxGL{color:var(--pink);text-align:center}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/SetListPriceModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,sCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAEA,2CACE,oCAAA,CACA,kBAAA,CACA,UAAA,CCoBF,0CDvBA,2CAMI,gCAAA,CACA,oBAAA,CACA,eAAA,CACA,YAAA,CAAA,CAIJ,6CACE,UAAA,CAGF,0EACE,eAAA,CACA,UAAA,CCIF,0CDNA,0EAKI,eAAA,CAAA,CAIJ,wEACE,iBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SetListPriceModal__container___m5Sf9",
	"actions": "SetListPriceModal__actions___xt7dS",
	"error": "SetListPriceModal__error___tuxGL"
};
export default ___CSS_LOADER_EXPORT___;
