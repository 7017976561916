// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationMobile__button___iacnL{background-color:rgba(0,0,0,0);border:2px solid rgba(0,0,0,0);padding:0}.NavigationMobile__button___iacnL:active,.NavigationMobile__button___iacnL:focus,.NavigationMobile__button___iacnL:hover{background-color:var(--gold);border-radius:.25rem;outline:none}.NavigationMobile__button___iacnL:active svg,.NavigationMobile__button___iacnL:focus svg,.NavigationMobile__button___iacnL:hover svg{fill:#000}.NavigationMobile__menuIcon___Y7tlz{fill:var(--white);width:1.5rem}.NavigationMobile__closeIcon___geHDq{height:1.5rem;stroke-width:2px;stroke:var(--white);width:1.5rem}", "",{"version":3,"sources":["webpack://./src/webReservations/navigation/NavigationMobile.scss"],"names":[],"mappings":"AAAA,kCACE,8BAAA,CACA,8BAAA,CACA,SAAA,CAEA,yHAGE,4BAAA,CACA,oBAAA,CACA,YAAA,CAEA,qIACE,SAAA,CAKN,oCACE,iBAAA,CACA,YAAA,CAGF,qCACE,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "NavigationMobile__button___iacnL",
	"menuIcon": "NavigationMobile__menuIcon___Y7tlz",
	"closeIcon": "NavigationMobile__closeIcon___geHDq"
};
export default ___CSS_LOADER_EXPORT___;
