import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

export const useHandleClickOutside = <T extends HTMLElement = HTMLDivElement>(
  handleClick: () => void,
): { ref: RefObject<T> } => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClick();
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref, handleClick]);

  return { ref };
};
