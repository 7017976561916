import { Link } from 'react-router-dom';
import Logo from '@assets/icons/logo.svg';
import { LANDING_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import styles from './EmailVerification.scss';

export const EmailVerificationSuccessPage = () => (
  <main className={styles.container}>
    <Link aria-label="Peak home page" to={LANDING_PATH}>
      <Logo className={styles.logo} role="presentation" />
    </Link>
    <h1 className={typography.d3}>Success!</h1>
    <p className={typography.c1}>Your email has been&nbsp;verified.</p>
  </main>
);
