import { useParams } from 'react-router-dom';
import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { LoadingPage } from '@components/LoadingPage';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { mapToReservationStatus } from 'webReservations/reservations/statusHelpers';
import { NotFoundPage } from '../errors/NotFound/NotFoundPage';
import { useReservationDetails } from '../hooks/useReservationDetails';
import { DetailsPageView } from './DetailsPageView';
import { ReservationDetailsPanel } from './ReservationDetailsPanel';

export const ReservationDetailsPage = () => {
  const { reservationId } = useParams();
  useDocumentTitle('My Reservation Details | Peak Reservations');

  const { fetchReservation, reservation, isReservationLoading, topOffer } =
    useReservationDetails(reservationId);

  if (isReservationLoading) {
    return <LoadingPage />;
  }

  if (!reservation || !reservationId) {
    return <NotFoundPage />;
  }

  return (
    <DetailsPageView
      floorPlanComponent={<SnapshotFloorPlan reservationId={reservationId} />}
      status={mapToReservationStatus(reservation.status)}
      restaurant={reservation.restaurant}
      date={reservation.date}
      guestCount={reservation.guestCount}
      listingPublicName={reservation.listing.publicName}
      time={reservation.time}
    >
      <ReservationDetailsPanel
        fetchReservation={fetchReservation}
        reservation={reservation}
        reservationId={reservationId}
        topOffer={topOffer}
      />
    </DetailsPageView>
  );
};
