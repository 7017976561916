import { Navigate, Outlet, useRouteLoaderData } from 'react-router-dom';
import { RestaurantStatus } from '@shared/types/restaurants';
import { LANDING_PATH } from 'webReservations/paths';
import { type RestaurantDetails } from './apiHelpers';

export const IsDiscoverable = () => {
  const { status } = useRouteLoaderData('restaurant') as RestaurantDetails;

  return status === RestaurantStatus.Archived ? (
    <Navigate to={LANDING_PATH} />
  ) : (
    <Outlet />
  );
};
