import { Link, useLoaderData } from 'react-router-dom';
import { type Restaurant } from './apiHelpers';
import styles from './RestaurantsPage.scss';

export const RestaurantsPage = () => {
  const data = useLoaderData() as Restaurant[];

  return (
    <div className={styles.container}>
      {data.map(({ name, locationSlug, nameSlug, id }) => (
        <Link key={id} to={`/${locationSlug}/${nameSlug}`}>
          {name}&nbsp;
        </Link>
      ))}
    </div>
  );
};
