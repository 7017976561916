import type { CSSProperties } from 'react';

export const DEFAULT_ICON_WIDTH = 60;
const DEFAULT_TABLE_ICON_ORIENTATION = '0.00';

export const calculateIconStyle = ({
  iconWidthScale,
  orientation = DEFAULT_TABLE_ICON_ORIENTATION,
  tableIconScale,
}: {
  iconWidthScale: string;
  orientation: string;
  tableIconScale: number;
}): CSSProperties => ({
  transform: `rotate(${orientation}deg)`,
  width: DEFAULT_ICON_WIDTH * tableIconScale * Number(iconWidthScale),
});

export const calculateContainerStyle = ({
  left,
  tableIconScale,
  top,
}: {
  left: string;
  tableIconScale: number;
  top: string;
}): CSSProperties => ({
  left: Number(left) * tableIconScale,
  top: Number(top) * tableIconScale,
});
