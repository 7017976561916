import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { getFloorPlanForRestaurantId } from 'webReservations/floorPlans/apiHelpers';

export const useFloorPlan = (restaurantId: string, dynamic = false) => {
  const [isLoading, setIsLoading] = useState(true);
  const [floorPlan, setFloorPlan] = useState<FloorPlanData>({
    id: 'not-loaded',
    backgroundSrc: undefined,
    floorPlanTables: [],
  });

  const fetchFloorPlan = async () => {
    try {
      const floorPlanResponse = await getFloorPlanForRestaurantId(
        restaurantId,
        dynamic,
      );
      if (isSuccessResponse(floorPlanResponse)) setFloorPlan(floorPlanResponse);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchFloorPlan();
  }, []);

  return { floorPlan, isLoading };
};
