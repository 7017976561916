import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './Address.scss';

interface AddressProps {
  value: string;
}

export const Address = ({ value }: AddressProps) => (
  <p className={cx(typography.t1, styles.container)}>
    <Icon className={styles.icon} name="locationArrow" />
    {value}
  </p>
);
