import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { ReactNode } from 'react';

const getApiKey = () => {
  const apiKey = process.env.STRIPE_API_PUBLISHABLE_KEY;
  if (!apiKey) {
    throw new Error('Stripe Api Publishable Key is required');
  }
  return apiKey;
};

const promise = loadStripe(getApiKey(), { apiVersion: '2024-04-10' });

interface StripeElementsProps {
  children: ReactNode;
}

export const StripeElements = ({ children }: StripeElementsProps) => (
  <Elements stripe={promise}>{children}</Elements>
);
