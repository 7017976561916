// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/images/plate-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingPage__container___kcItl{display:flex;align-items:center;justify-content:center;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x;background-attachment:fixed;background-position:center}.LoadingPage__title___Kj4Sf{font-family:\"PP Woodland\",sans-serif;color:var(--white);margin-top:32px}.LoadingPage__content___fgEiZ{position:absolute}.LoadingPage__spinnerContainer___zPSdX{display:flex;align-items:center;justify-content:center}.LoadingPage__logo___SR2Yx{position:absolute;width:5rem;height:5rem;stroke:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/shared/components/LoadingPage.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,wDAAA,CACA,0BAAA,CACA,2BAAA,CACA,0BAAA,CAGF,4BACE,oCAAA,CACA,kBAAA,CACA,eAAA,CAGF,8BACE,iBAAA,CAGF,uCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,2BACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LoadingPage__container___kcItl",
	"title": "LoadingPage__title___Kj4Sf",
	"content": "LoadingPage__content___fgEiZ",
	"spinnerContainer": "LoadingPage__spinnerContainer___zPSdX",
	"logo": "LoadingPage__logo___SR2Yx"
};
export default ___CSS_LOADER_EXPORT___;
