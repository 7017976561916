import { kebabCase } from 'lodash-es';
import ReactGA from 'react-ga4';
import type { PricingInfo } from '@shared/pricingInfoApi';
import { centsToDecimal } from '@utils/currency';
import type { TSelectedAvailability } from 'webReservations/context/AvailabilityContext';
import type { RestaurantDetails } from 'webReservations/restaurant/apiHelpers';

interface PurchaseEvent {
  date: string;
  pricingInfo?: PricingInfo;
  receiptNumber: string;
  restaurantDetails: RestaurantDetails;
  selectedAvailability: TSelectedAvailability;
  type: string;
}

interface ReservationEvent {
  date: string;
  guestCount: number;
  price: number;
  restaurantName: string;
  publicName: string;
  time: string;
}

type StartOfferEvent = Omit<ReservationEvent, 'price' | 'guestCount'>;
type SendOfferEvent = StartOfferEvent & { offerAmount: number };

export const sendGa4PurchaseEvent = ({
  date,
  pricingInfo,
  receiptNumber,
  restaurantDetails,
  selectedAvailability,
  type,
}: PurchaseEvent) => {
  ReactGA.event('purchase', {
    currency: 'USD',
    items: [
      {
        item_category: restaurantDetails.name,
        item_category2: selectedAvailability.publicName,
        item_category3: type,
        item_name: `${restaurantDetails.locationSlug}-${restaurantDetails.nameSlug}-${kebabCase(selectedAvailability.publicName)}`,
        item_variant: `${date} ${selectedAvailability.time}`,
        price: pricingInfo ? centsToDecimal(pricingInfo.total) : 0,
      },
    ],
    tax: pricingInfo ? centsToDecimal(pricingInfo.tax) : 0,
    transaction_id: receiptNumber,
    value: pricingInfo ? centsToDecimal(pricingInfo.total) : 0,
  });
};

export const sendGa4StartReservationEvent = ({
  date,
  guestCount,
  price,
  restaurantName,
  publicName,
  time,
}: ReservationEvent) => {
  ReactGA.event('reservation_start', {
    date,
    guestCount,
    price,
    restaurantName,
    publicName,
    time,
  });
};

export const sendGa4CheckoutReservationEvent = ({
  date,
  guestCount,
  price,
  restaurantName,
  publicName,
  time,
}: ReservationEvent) => {
  ReactGA.event('reservation_checkout', {
    date,
    guestCount,
    price,
    restaurantName,
    publicName,
    time,
  });
};

export const sendGa4FinishReservationEvent = ({
  date,
  guestCount,
  price,
  restaurantName,
  publicName,
  time,
}: ReservationEvent) => {
  ReactGA.event('reservation_finish', {
    date,
    guestCount,
    price,
    restaurantName,
    publicName,
    time,
  });
};

export const sendGa4StartOfferEvent = ({
  date,
  restaurantName,
  publicName,
  time,
}: StartOfferEvent) => {
  ReactGA.event('offer_start', {
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4SendOfferEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: SendOfferEvent) => {
  ReactGA.event('offer_sent', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};
