import { NOT_FOUND, UNAUTHORIZED } from '@shared/statusCodes';
import type { ErrorResponse } from '@shared/types/apiHelpers';
import {
  ApiError,
  ApiResourceNotFoundError,
  ApiServerError,
  ApiUnauthorizedError,
} from './errors';

export const createResponseError = ({
  error,
  message,
  statusCode,
}: ErrorResponse) => {
  switch (statusCode) {
    case NOT_FOUND:
      return new ApiResourceNotFoundError(message);
    case UNAUTHORIZED:
      return new ApiUnauthorizedError(message);
    default:
      if (statusCode >= 500 && statusCode < 600)
        return new ApiServerError(statusCode, message);
      return new ApiError(statusCode, message, error);
  }
};
