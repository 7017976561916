import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import type { PricingInfo } from '@shared/pricingInfoApi';
import { getPricingInfo } from '@shared/pricingInfoApi';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useError } from '../hooks/useError';

export const usePricingInfo = (zipCode: string, price?: number) => {
  const setError = useError();
  const [pricingInfo, setPricingInfo] = useState<PricingInfo>();
  const [isLoading, setIsLoading] = useState(!!price);

  const fetchPricingInfo = useCallback(
    debounce(async (priceToGetInfoFor) => {
      setIsLoading(true);
      if (!priceToGetInfoFor) return;

      try {
        const pricingInfoResponse = await getPricingInfo({
          price: priceToGetInfoFor,
          zipCode,
        });
        if (isSuccessResponse(pricingInfoResponse)) {
          setPricingInfo(pricingInfoResponse);
        }
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }, 1000),
    [],
  );

  useEffect(() => {
    fetchPricingInfo(price);
  }, [price]);

  return {
    pricingInfo,
    isLoading,
  };
};
