import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

interface AuthenticatedUserFromApi {
  email: string;
  firstName: string;
  id: string;
  isRegistered: boolean;
  lastName: string;
  phone: string;
}

export interface AuthenticatedUser {
  email: string;
  firstName: string;
  id: string;
  isRegistered: boolean;
  lastName: string;
  phone: string;
}

interface AccountInfo {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  phoneVerificationCode: string;
  isTosAccepted: boolean;
}

export interface CheckAuthResponse {
  user?: AuthenticatedUser;
  isAuthenticated: boolean;
}

interface Credentials {
  phone: string;
  phoneVerificationCode: string;
}

export interface DescriptionResponse {
  description: string;
}

export const login = async (
  credentials: Credentials,
): Promise<ApiResponse<AuthenticatedUser>> => {
  const response: Response = await API.post('/web-login', credentials);

  if (!response.ok) {
    return response.json();
  }

  const jsonResponse: AuthenticatedUserFromApi = await response.json();

  return {
    id: jsonResponse.id,
    email: jsonResponse.email,
    firstName: jsonResponse.firstName,
    lastName: jsonResponse.lastName,
    phone: jsonResponse.phone,
    isRegistered: jsonResponse.isRegistered,
  };
};

export const signOut = async (): Promise<Response> =>
  API.post('/web-sign-out', {});

export const register = async (
  accountInfo: AccountInfo,
): Promise<ApiResponse<AuthenticatedUser>> => {
  const response: Response = await API.post('/web-register', accountInfo);

  return response.json();
};

export const sendLoginVerificationCode = async (
  phoneNumber: string,
): Promise<ApiResponse<DescriptionResponse>> => {
  const response: Response = await API.get(
    `/login/send-phone-verification-code?phone=${encodeURIComponent(
      phoneNumber,
    )}`,
  );
  return response.json();
};

export const sendRegistrationVerificationCode = async (
  phoneNumber: string,
): Promise<ApiResponse<DescriptionResponse>> => {
  const response: Response = await API.get(
    `/register/send-phone-verification-code?phone=${encodeURIComponent(
      phoneNumber,
    )}`,
  );
  return response.json();
};

export const checkAuth = async (): Promise<CheckAuthResponse> => {
  const response: Response = await API.get('/check-auth-web');
  return response.json();
};
