// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListPrice__updateListPrice___QnZY9{background-color:rgba(0,0,0,0);border:none;cursor:pointer;display:flex;justify-content:space-between;padding:1rem;width:100%}.ListPrice__updateListPrice___QnZY9 h3{margin:0}.ListPrice__updateListPrice___QnZY9 .ListPrice__listPriceAmount___qkq0Y{color:var(--accent300)}.ListPrice__setListPrice___QdANS{align-items:center;background-color:rgba(0,0,0,0);border:none;cursor:pointer;display:flex;gap:.5rem;justify-content:space-between;padding:.5rem;text-align:left;width:100%}.ListPrice__setListPrice___QdANS .ListPrice__instructionsContainer___xbCLU{border-right:2px solid var(--hairline);padding:.5rem;display:flex;flex-direction:column;gap:.5rem;width:inherit}.ListPrice__setListPrice___QdANS .ListPrice__instructionsContainer___xbCLU h3{margin:0}.ListPrice__setListPrice___QdANS .ListPrice__instructionsContainer___xbCLU p{color:var(--primary100);margin:0}.ListPrice__setListPrice___QdANS svg{height:1.5rem;stroke-width:1.5px;stroke:var(--accent300);transform:scaleX(-1);width:1.5rem}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ListPrice.scss"],"names":[],"mappings":"AAAA,oCACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,YAAA,CACA,UAAA,CAEA,uCACE,QAAA,CAGF,wEACE,sBAAA,CAIJ,iCACE,kBAAA,CACA,8BAAA,CACA,WAAA,CACA,cAAA,CACA,YAAA,CACA,SAAA,CACA,6BAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA,CAEA,2EACE,sCAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CACA,aAAA,CAEA,8EACE,QAAA,CAGF,6EACE,uBAAA,CACA,QAAA,CAIJ,qCACE,aAAA,CACA,kBAAA,CACA,uBAAA,CACA,oBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateListPrice": "ListPrice__updateListPrice___QnZY9",
	"listPriceAmount": "ListPrice__listPriceAmount___qkq0Y",
	"setListPrice": "ListPrice__setListPrice___QdANS",
	"instructionsContainer": "ListPrice__instructionsContainer___xbCLU"
};
export default ___CSS_LOADER_EXPORT___;
