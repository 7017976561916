/* eslint-disable max-classes-per-file */
import { NOT_FOUND, UNAUTHORIZED } from '@shared/statusCodes';

/**
 * Thrown when the API returns an error response.
 *
 * The 'message' field corresponds to the "message" field in the JSON response.
 *
 * When handling a caught error, prefer checking for subclasses like
 * ApiResourceNotFoundError.
 */
export class ApiError extends Error {
  constructor(
    public statusCode: number,
    message?: string,
    public error?: string,
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

export class ApiResourceNotFoundError extends ApiError {
  constructor(message?: string) {
    super(NOT_FOUND, message);
    this.name = 'ApiResourceNotFoundError';
  }
}

export class ApiServerError extends ApiError {
  constructor(statusCode: number, message?: string) {
    super(statusCode, message);
    this.name = 'ApiServerError';
  }
}

export class ApiUnauthorizedError extends ApiError {
  constructor(message?: string) {
    super(UNAUTHORIZED, message);
    this.name = 'ApiUnauthorizedError';
  }
}
