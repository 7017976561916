import { createContext, type ReactNode, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { type Environment, PRODUCTION } from '@shared/environments';

const getCurrentEnvironmentOrDefault = (): Environment =>
  (process.env.ENV as Environment) || 'production';

const buildFeatureConfigWithOverrides = <F extends string>(
  defaultConfig: FeatureConfig<F>,
  getOverrideFunc: GetOverrideFunc<F>,
): FeatureConfig<F> => {
  const environment = getCurrentEnvironmentOrDefault();
  if (environment === PRODUCTION) return defaultConfig;

  const featureList: F[] = Object.keys(defaultConfig) as F[];

  return featureList.reduce((acc, feature) => {
    const overrideValue = getOverrideFunc(feature);
    if (overrideValue === null) return acc;

    return {
      ...acc,
      [feature]: overrideValue,
    };
  }, defaultConfig);
};

export type FeatureConfig<F extends string> = Record<F, boolean>;
type GetOverrideFunc<F extends string> = (feature: F) => boolean | null;

type GenericFeature = string;
interface FeatureFlagContextState<FeatureFlag extends GenericFeature> {
  isEnabled: (featureFlag: FeatureFlag) => boolean;
}

const FeatureFlagContext = createContext<FeatureFlagContextState<any>>(
  {} as FeatureFlagContextState<any>,
);
FeatureFlagContext.displayName = 'FeatureFlag';

export const useFeatureFlagContext = <
  FeatureFlag extends GenericFeature,
>(): FeatureFlagContextState<FeatureFlag> => {
  const context = useContext<FeatureFlagContextState<FeatureFlag>>(
    FeatureFlagContext as unknown as React.Context<
      FeatureFlagContextState<FeatureFlag>
    >,
  );
  if (!context) {
    throw new Error(
      'useFeatureFlagContext must be used under FeatureFlagContext',
    );
  }
  return context;
};

export const FeatureFlagContextProvider = <FeatureFlag extends string>({
  children,
  environmentConfig,
}: {
  children: ReactNode;
  environmentConfig: Readonly<Record<Environment, FeatureConfig<FeatureFlag>>>;
}) => {
  const [searchParams] = useSearchParams();

  const environment = getCurrentEnvironmentOrDefault();

  const getOverride: GetOverrideFunc<FeatureFlag> = (feature: FeatureFlag) => {
    if (searchParams.get(feature) === null) return null;
    return searchParams.get(feature) === 'true';
  };

  const value = useMemo<FeatureFlagContextState<any>>(() => {
    const defaultFlagState: FeatureConfig<FeatureFlag> =
      environmentConfig[environment];

    const flagState: FeatureConfig<FeatureFlag> =
      buildFeatureConfigWithOverrides(defaultFlagState, getOverride);

    const shouldLogFeatureFlagState =
      environment !== PRODUCTION && process.env.NODE_ENV !== 'test';

    const isEnabled = (flagName: FeatureFlag) => flagState[flagName];

    if (shouldLogFeatureFlagState) {
      // eslint-disable-next-line no-console
      console.table(flagState);
    }

    return {
      isEnabled,
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
