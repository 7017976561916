// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationStatus__confirmed___UJQhq,.ReservationStatus__completed___eMW7C{color:var(--confirmedGreen)}.ReservationStatus__canceled___rquQn{color:var(--pink)}.ReservationStatus__resold___HasOQ{color:var(--soldBlue)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationStatus.scss"],"names":[],"mappings":"AAAA,4EAEE,2BAAA,CAGF,qCACE,iBAAA,CAGF,mCACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmed": "ReservationStatus__confirmed___UJQhq",
	"completed": "ReservationStatus__completed___eMW7C",
	"canceled": "ReservationStatus__canceled___rquQn",
	"resold": "ReservationStatus__resold___HasOQ"
};
export default ___CSS_LOADER_EXPORT___;
