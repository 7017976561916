import { type ApiResponse } from '@shared/types/apiHelpers';
import type { FloorPlanSnapshotData } from '@shared/types/floorPlans';
import API from '../../api/apiClient';

export const getFloorPlanSnapshotForReservationId = async (
  reservationId: string,
): Promise<ApiResponse<FloorPlanSnapshotData>> => {
  const response = await API.get(
    `/reservations/${reservationId}/floor-plan-snapshot`,
  );

  return response.json();
};
