import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { todayInTimezone } from '@utils/date';
import type { Availability, RestaurantDetails } from '../restaurant/apiHelpers';
import { getAvailabilities, getClosureForDate } from '../restaurant/apiHelpers';
import {
  calculateFilteredData,
  getAvailabilitiesGroupedByTime,
} from './availabilityDerivedState';
import {
  availabilityReducer,
  clearSelectedAvailability,
  initialAvailabilityState,
  setAvailabilities,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from './availabilityReducer';
import { useRestaurantContext } from './RestaurantContext';

export interface TSelectedAvailability {
  isBiddable: boolean;
  isBuyable: boolean;
  listingId: string;
  purchasePrice: number | null;
  publicName: string;
  time: string;
}

export interface AvailabilityContextState {
  clearSelectedAvailability: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: Availability[];
  filteredAvailabilitiesGroupedByTime: Record<string, Availability[]>;
  hasAvailabilitiesOnGivenDay: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isLoading: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: TSelectedAvailability | null;
  selectedDate: string;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string | null;
  setSelectedAvailability: (availability: TSelectedAvailability) => void;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string | null) => void;
}

const AvailabilityContext = createContext<AvailabilityContextState | null>(
  null,
);
AvailabilityContext.displayName = 'AvailabilityContext';

export const useAvailabilityContext = () =>
  useDefinedContext(AvailabilityContext);

interface AvailabilityContextProviderProps {
  children: ReactNode;
}

const useIsRestaurantClosedToday = (restaurantDetails: RestaurantDetails) => {
  const [isClosedToday, setIsClosedToday] = useState(true);
  const todayInRestaurantTimezone = todayInTimezone(restaurantDetails.timezone);
  useEffect(() => {
    const fetchIsOpenToday = async (restaurantId: string, date: string) => {
      const response = await getClosureForDate(restaurantId, date);

      if (isSuccessResponse(response)) {
        setIsClosedToday(response.length > 0);
      } else {
        setIsClosedToday(false);
      }
    };

    if (restaurantDetails.id) {
      void fetchIsOpenToday(restaurantDetails.id, todayInRestaurantTimezone);
    }
  }, [restaurantDetails.id]);

  return { isClosedToday, todayInRestaurantTimezone };
};

export const AvailabilityContextProvider = ({
  children,
}: AvailabilityContextProviderProps) => {
  const { restaurantDetails } = useRestaurantContext();
  const { isClosedToday, todayInRestaurantTimezone } =
    useIsRestaurantClosedToday(restaurantDetails);
  const [state, dispatch] = useReducer(
    availabilityReducer,
    initialAvailabilityState(),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
  } = state;

  // Ideally we would not load the availability context until we had the restaurantDetails, but that requires significant refactors.
  // This useEffect ensures the restaurant's current date is used when restaurantDetails is updated.
  useEffect(() => {
    if (restaurantDetails.timezone) {
      dispatch(setSelectedDate(todayInRestaurantTimezone));
    }
  }, [restaurantDetails]);

  const { isPending: isLoading } = useAbortEffect(async () => {
    if (restaurantDetails.id) {
      const allAvailabilities = await getAvailabilities(
        restaurantDetails.id,
        selectedDate,
      );
      dispatch(setAvailabilities(allAvailabilities));
    }
  }, [selectedDate, restaurantDetails]);

  const {
    filteredAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state);

  const filteredAvailabilitiesGroupedByTime =
    getAvailabilitiesGroupedByTime(state);

  const isSelectedDateToday: boolean = restaurantDetails?.timezone
    ? todayInRestaurantTimezone === selectedDate
    : false;

  const value = useMemo<AvailabilityContextState>(
    () => ({
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      filteredAvailabilities,
      filteredAvailabilitiesGroupedByTime,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasUnsupportedGuestCount,
      isClosedToday,
      isLoading,
      selectedAvailability,
      selectedDate,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: TSelectedAvailability) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: string) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string | null) => dispatch(setSelectedTime(time)),
      expandedAvailabilityListingIds,
      isSelectedDateToday,
    }),
    [state],
  );

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
