// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkStyledAsButton__link___iVr5S{align-items:center;border-radius:4px;color:var(--white);display:flex;font-size:16px;font-weight:600;height:48px;justify-content:center;line-height:24px;padding:8px 20px}@media only screen and (min-width: 720px){.LinkStyledAsButton__link___iVr5S{font-size:14px;height:40px;line-height:24px}}", "",{"version":3,"sources":["webpack://./src/shared/components/linkStyledAsButton/LinkStyledAsButton.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,kCACE,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,sBAAA,CACA,gBAAA,CACA,gBAAA,CCWE,0CDrBJ,kCAaI,cAAA,CACA,WAAA,CACA,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "LinkStyledAsButton__link___iVr5S"
};
export default ___CSS_LOADER_EXPORT___;
