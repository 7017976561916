// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountsLayout__main___Nkvn4{display:flex;flex-direction:row}.AccountsLayout__content___huUdk{flex:1;padding:2.5rem 1rem}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/AccountsLayout.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,kBAAA,CAGF,iCACE,MAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "AccountsLayout__main___Nkvn4",
	"content": "AccountsLayout__content___huUdk"
};
export default ___CSS_LOADER_EXPORT___;
