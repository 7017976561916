// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckoutReceiptDetails__price___gQWre{margin-bottom:8px}.CheckoutReceiptDetails__row___Ne1hZ{display:flex;justify-content:space-between;align-items:center}.CheckoutReceiptDetails__subText___Khu4G{color:var(--darkGrey100);font-variant-numeric:tabular-nums}.CheckoutReceiptDetails__total___Xc3mq{margin-top:16px}", "",{"version":3,"sources":["webpack://./src/shared/components/checkout/CheckoutReceiptDetails.scss"],"names":[],"mappings":"AAAA,uCACE,iBAAA,CAGF,qCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,yCACE,wBAAA,CACA,iCAAA,CAGF,uCACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": "CheckoutReceiptDetails__price___gQWre",
	"row": "CheckoutReceiptDetails__row___Ne1hZ",
	"subText": "CheckoutReceiptDetails__subText___Khu4G",
	"total": "CheckoutReceiptDetails__total___Xc3mq"
};
export default ___CSS_LOADER_EXPORT___;
