import { useState } from 'react';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';
import { Icon } from '@components/icon/Icon';
import styles from './ZoomControls.scss';

export interface ZoomControlProps {
  minScale: number;
  maxScale: number;
  scale: number;
}

export const ZoomControls = ({
  minScale,
  maxScale,
  scale,
}: ZoomControlProps) => {
  const { zoomIn, zoomOut } = useControls();
  const [currentScale, setCurrentScale] = useState(scale);

  useTransformEffect(({ state }) => {
    setCurrentScale(state.scale);
  });

  return (
    <div className={styles.container}>
      <button
        aria-label="zoom out"
        className={styles.button}
        onClick={() => zoomOut()}
        type="button"
        disabled={currentScale === minScale}
      >
        <Icon className={styles.icon} name="zoomOut" />
      </button>
      <span className={styles.divider} />
      <button
        aria-label="zoom in"
        className={styles.button}
        onClick={() => zoomIn()}
        type="button"
        disabled={currentScale === maxScale}
      >
        <Icon className={styles.icon} name="zoomIn" />
      </button>
    </div>
  );
};
