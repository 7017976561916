import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  DEMO,
  DEVELOPMENT,
  type Environment,
  STAGING,
} from '@shared/environments';

const prependEnvironmentName = (title: string) => {
  let modifiedTitle = title;
  const env = process.env.ENV as Environment;
  if (env === DEVELOPMENT) modifiedTitle = `development - ${title}`;
  if (env === STAGING) modifiedTitle = `staging - ${title}`;
  if (env === DEMO) modifiedTitle = `demo - ${title}`;
  return modifiedTitle;
};

const useDocumentTitle = (title: string): void => {
  useEffect(() => {
    const finalTitle = prependEnvironmentName(title);
    ReactGA.event('page_view', { page_title: finalTitle });

    document.title = finalTitle;

    return () => {
      document.title = 'Peak Reservations';
    };
  }, []);
};

export { useDocumentTitle };
