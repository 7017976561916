import { ApiStatus } from 'webReservations/reservations/apiHelpers';

export enum ReservationStatusType {
  Canceled = 'Reservation Canceled',
  Completed = 'Reservation Completed',
  Confirmed = 'Reservation Confirmed',
  Sold = 'Reservation Sold',
}

export const mapToReservationStatus = (
  value: string,
): ReservationStatusType | undefined => {
  switch (value) {
    case 'CANCELED':
      return ReservationStatusType.Canceled;
    case 'COMPLETED':
      return ReservationStatusType.Completed;
    case 'CONFIRMED':
      return ReservationStatusType.Confirmed;
    case 'SOLD':
      return ReservationStatusType.Sold;
    default:
      return undefined;
  }
};

export const INACTIVE_RESERVATION_STATUS_MAP = {
  [ApiStatus.Active]: ReservationStatusType.Completed,
  [ApiStatus.Finished]: ReservationStatusType.Completed,
  [ApiStatus.AutoResolved]: ReservationStatusType.Completed,
  [ApiStatus.NoShow]: ReservationStatusType.Completed,
  [ApiStatus.Canceled]: ReservationStatusType.Canceled,
  [ApiStatus.Resold]: ReservationStatusType.Sold,
} as const;
