// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlledCheckboxInput__container___XB4yf input{margin:0}.ControlledCheckboxInput__container___XB4yf>div{display:flex;gap:1rem}.ControlledCheckboxInput__container___XB4yf>div label{display:flex;flex-direction:row-reverse;gap:1rem}.ControlledCheckboxInput__container___XB4yf>div label.ControlledCheckboxInput__labelError___RC7rg{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/formInputs/ControlledCheckboxInput.scss"],"names":[],"mappings":"AACE,kDACE,QAAA,CAGF,gDACE,YAAA,CACA,QAAA,CAEA,sDACE,YAAA,CACA,0BAAA,CACA,QAAA,CAEA,kGACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlledCheckboxInput__container___XB4yf",
	"labelError": "ControlledCheckboxInput__labelError___RC7rg"
};
export default ___CSS_LOADER_EXPORT___;
