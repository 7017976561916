import { Outlet } from 'react-router-dom';
import { StripeElements } from '@components/StripeElements';
import { WideView } from '@components/wideView/WideView';
import { useUserContext } from '../context/UserContext';
import { Unauthenticated } from '../components/Unauthenticated';
import { Layout } from '../layout/Layout';
import styles from './AccountsLayout.scss';
import { AccountsSidebar } from './AccountsSidebar';

export const AccountsLayout = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <StripeElements>
      <Layout>
        {isAuthenticated ? (
          <main className={styles.main}>
            <WideView wideElement={<AccountsSidebar />} />
            <div className={styles.content}>
              <Outlet />
            </div>
          </main>
        ) : (
          <Unauthenticated
            className={styles.unauthenticated}
            subtitle="Login to manage your notifications, balance, and more."
            title="Manage your settings"
          />
        )}
      </Layout>
    </StripeElements>
  );
};
