import { centsToDollar, centsToWholeDollar } from '@utils/currency';
import { isoToShortDate } from '@utils/date';
import typography from '~styles/typography.scss';
import type { ReservationWithDetails } from './apiHelpers';
import styles from './ReceiptDetails.scss';

interface ReceiptDetailsProps {
  reservation: ReservationWithDetails;
}

export const ReceiptDetails = ({ reservation }: ReceiptDetailsProps) => {
  const { soldForPrice, transaction } = reservation;

  return (
    <section className={styles.container}>
      <h2 className={typography.h4} id="rd-h">
        Receipt
      </h2>
      <dl aria-labelledby="rd-h" className={typography.t1} role="group">
        <dt aria-labelledby="rd-pd-t" id="rd-pd-t">
          Purchase Date
        </dt>
        <dd aria-labelledby="rd-pd-d" id="rd-pd-d">
          {isoToShortDate(transaction.purchaseDate)}
        </dd>
        <dt aria-labelledby="rd-pp-t" id="rd-pp-t">
          Purchase Price
        </dt>
        <dd aria-labelledby="rd-pp-d" id="rd-pp-d">
          {centsToWholeDollar(transaction.price)}
        </dd>
        {transaction.refundAmount && (
          <>
            <dt aria-labelledby="rd-ra-t" id="rd-ra-t">
              Refund Amount
            </dt>
            <dd aria-labelledby="rd-ra-d" id="rd-ra-d">
              {centsToDollar(transaction.refundAmount)}
            </dd>
          </>
        )}
        {soldForPrice && (
          <>
            <dt aria-labelledby="rd-sf-t" id="rd-sf-t">
              Sold For
            </dt>
            <dd aria-labelledby="rd-sf-d" id="rd-sf-d">
              {centsToWholeDollar(soldForPrice)}
            </dd>
          </>
        )}
        <dt aria-labelledby="rd-pt-t" id="rd-pt-t">
          Payment Type
        </dt>
        <dd aria-labelledby="rd-pt-d" id="rd-pt-d">
          {transaction.paymentMethod}
        </dd>
        <dt aria-labelledby="rd-rn-t" id="rd-rn-t">
          Receipt Number
        </dt>
        <dd aria-labelledby="rd-rn-d" id="rd-rn-d">
          {transaction.receiptNumber}
        </dd>
      </dl>
    </section>
  );
};
