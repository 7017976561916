import cx from 'classnames';
import styles from './Divider.scss';

interface DividerProps {
  className?: string;
}

export const Divider = ({ className }: DividerProps) => (
  <div role="separator" className={cx(styles.divider, className)} />
);
