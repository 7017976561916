// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerifyOTPModal__container___Brurn{display:flex;flex-direction:column;align-items:center;justify-content:center}.VerifyOTPModal__info___gt9TS{color:var(--white70)}.VerifyOTPModal__otpContainer___xoJK2{cursor:text;display:flex;justify-content:space-between;margin:20px 0;width:100%}.VerifyOTPModal__otpDigit___MFnxV{align-items:center;border-radius:.25rem;border:1px solid var(--Hairline, #404c60);color:var(--white);display:flex;height:3.5rem;justify-content:center;width:3rem}.VerifyOTPModal__focused___s4XWK{border-width:4px;border-color:var(--accent300)}.VerifyOTPModal__hiddenInput___knFh9{opacity:0;width:0;height:0}.VerifyOTPModal__button___uGBTT{align-self:stretch}.VerifyOTPModal__createAccountButton___Jzsmd,.VerifyOTPModal__backButton___vdoeH{margin-top:20px}.VerifyOTPModal__error___ch5Rc{color:var(--pink);margin-top:.5rem}", "",{"version":3,"sources":["webpack://./src/webReservations/auth/VerifyOTPModal.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,8BACE,oBAAA,CAGF,sCACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CACA,UAAA,CAGF,kCACE,kBAAA,CACA,oBAAA,CACA,yCAAA,CACA,kBAAA,CACA,YAAA,CACA,aAAA,CACA,sBAAA,CACA,UAAA,CAGF,iCACE,gBAAA,CACA,6BAAA,CAGF,qCACE,SAAA,CACA,OAAA,CACA,QAAA,CAGF,gCACE,kBAAA,CAGF,iFAEE,eAAA,CAGF,+BACE,iBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VerifyOTPModal__container___Brurn",
	"info": "VerifyOTPModal__info___gt9TS",
	"otpContainer": "VerifyOTPModal__otpContainer___xoJK2",
	"otpDigit": "VerifyOTPModal__otpDigit___MFnxV",
	"focused": "VerifyOTPModal__focused___s4XWK",
	"hiddenInput": "VerifyOTPModal__hiddenInput___knFh9",
	"button": "VerifyOTPModal__button___uGBTT",
	"createAccountButton": "VerifyOTPModal__createAccountButton___Jzsmd",
	"backButton": "VerifyOTPModal__backButton___vdoeH",
	"error": "VerifyOTPModal__error___ch5Rc"
};
export default ___CSS_LOADER_EXPORT___;
