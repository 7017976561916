export interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

/**
 * @deprecated - the newer api pattern only returns success values; errors are
 * thrown as an ApiError
 */
export type ApiResponse<TSuccess> = TSuccess | ErrorResponse;

/**
 * @deprecated - the newer api pattern throws ApiError (or a subclass)
 * instead of returning this structure
 */
export const isErrorResponse = <T extends object>(
  response: ApiResponse<T>,
): response is ErrorResponse => 'error' in response;

/**
 * @deprecated - this is not needed with the newer api pattern - if it returns
 * without throwing then it is a success response
 */
export const isSuccessResponse = <T extends object>(
  response: ApiResponse<T>,
): response is T => !isErrorResponse(response);

/**
 * @deprecated - the newer api pattern always detects error responses and throws
 * an ApiError (or a subclass)
 */
export const getErrorResponseMessage = async (
  response: Response,
): Promise<string> => {
  const payload = (await response.json()) as ErrorResponse;

  return payload.message;
};
