import cx from 'classnames';
import styles from './Spinner.scss';

interface SpinnerProps {
  className?: string;
}

export const Spinner = ({ className }: SpinnerProps) => (
  <div
    aria-label="loading"
    className={cx(styles.spinner, className)}
    role="status"
    title="loading"
  />
);
