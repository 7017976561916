import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import type { FloorPlanTableData } from '@shared/types/floorPlans';

interface StaticGuestFloorPlanProps {
  backgroundSrc?: string;
  highlightedListingId?: string;
  tables: FloorPlanTableData[];
}

export const StaticGuestFloorPlan = ({
  backgroundSrc,
  highlightedListingId,
  tables,
}: StaticGuestFloorPlanProps) => {
  const calculateIsHighlighted = (
    floorPlanTable: FloorPlanTableData,
  ): boolean =>
    !!highlightedListingId &&
    floorPlanTable.listings.some(
      (listing) => listing.id === highlightedListingId,
    );

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    floorPlanTablesRendererFactory({
      calculateIsHighlighted,
      tables,
    });

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
