import {
  CENTS_IN_DOLLAR,
  MAXIMUM_STRIPE_TRANSACTION_AMOUNT_IN_CENTS,
} from '@utils/currency';

export const MINIMUM_OFFER_AMOUNT_IN_DOLLARS = 1;
export const MAXIMUM_SALES_TAX_IMPLICATION = 0.1;
const PEAK_FEE = 0.05;
const STRIPE_MAX_NET_OFFER = MAXIMUM_SALES_TAX_IMPLICATION + PEAK_FEE;
const NET_MAXIMUM_OFFER_AMOUNT_IN_CENTS =
  STRIPE_MAX_NET_OFFER + MAXIMUM_STRIPE_TRANSACTION_AMOUNT_IN_CENTS;

export const MAXIMUM_OFFER_AMOUNT_IN_DOLLARS = Math.floor(
  NET_MAXIMUM_OFFER_AMOUNT_IN_CENTS / CENTS_IN_DOLLAR,
);
