import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import buttonStyles from '../button/Button.scss';
import styles from './LinkStyledAsButton.scss';

export interface LinkStyledAsButtonProps {
  label: string;
  className?: string;
  rel?: string;
  target?: string;
  to: string;
  variant: ButtonVariants;
  onClick?: () => void;
  state?: Record<string, any>;
}

export const LinkStyledAsButton = ({
  label,
  className,
  rel,
  target,
  to,
  variant,
  onClick,
  state,
}: LinkStyledAsButtonProps) => {
  const maybeAClassName = className ? { [className]: className } : {};

  const linkClassNames = cx({
    [styles.link]: true,
    [buttonStyles.btnTertiary]: variant === ButtonVariants.Tertiary,
    [buttonStyles.btnPrimary]: variant === ButtonVariants.Primary,
    [buttonStyles.btnSecondary]: variant === ButtonVariants.Secondary,
    ...maybeAClassName,
  });

  return (
    <Link
      className={cx(linkClassNames)}
      rel={rel}
      target={target}
      to={to}
      onClick={onClick}
      state={state}
    >
      {label}
    </Link>
  );
};
