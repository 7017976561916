// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentMethodsPage__container___djg44{align-items:center;background-color:var(--darkBlue);border-radius:.5rem;display:flex;flex-direction:column;gap:2rem;margin:auto;max-width:35rem;padding:4rem 1rem;width:100%}.PaymentMethodsPage__container___djg44 h1{margin:0 auto}.PaymentMethodsPage__container___djg44 ul{align-items:flex-start;display:inline-flex;flex-direction:column;gap:.5rem;width:100%}.PaymentMethodsPage__container___djg44 p{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/PaymentMethodsPage.scss"],"names":[],"mappings":"AAEA,uCACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CAEA,0CACE,aAAA,CAGF,0CACE,sBAAA,CACA,mBAAA,CACA,qBAAA,CACA,SAAA,CACA,UAAA,CAGF,yCACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PaymentMethodsPage__container___djg44"
};
export default ___CSS_LOADER_EXPORT___;
