import cx from 'classnames';
import { Link } from 'react-router-dom';
import { CheckoutReceiptDetails } from '@components/checkout/CheckoutReceiptDetails';
import linkStyles from '~styles/links.scss';
import { TERMS_OF_SERVICE_PATH } from '../paths';
import { OfferCancellationPolicyNotice } from './OfferCancellationPolicyNotice';
import styles from './OfferCheckoutReceipt.scss';

interface OfferCheckoutReceiptProps {
  fee: number;
  price: number;
  tax: number;
  total: number;
  date: string;
}

export const OfferCheckoutReceipt = ({
  fee,
  price,
  tax,
  total,
  date,
}: OfferCheckoutReceiptProps) => (
  <div data-testid="offer-checkout-receipt">
    <CheckoutReceiptDetails
      className={styles.container}
      fee={fee}
      priceLabel="Offer Amount"
      price={price}
      tax={tax}
      total={total}
    />

    <Link
      className={cx(linkStyles.linkPrimary, styles.row)}
      to={`${TERMS_OF_SERVICE_PATH}#cancellations-and-refunds`}
      target="_blank"
    >
      Refund Policy
    </Link>

    <OfferCancellationPolicyNotice date={date} />
  </div>
);
