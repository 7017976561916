import cx from 'classnames';
import { kebabCase } from 'lodash-es';
import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
} from 'react-accessible-accordion';
import {
  PeakAccordionButton,
  PeakAccordionItemPanel,
} from '@components/accordion/Accordion';
import typography from 'shared/styles/typography.scss';
import type { Faq } from './faq';
import styles from './FAQPage.scss';
import { sendGa4FAQOpenClickEvent } from './gaHelpers';

interface FAQSectionProps {
  faqs: Faq[];
  sectionName: string;
}

const DEFAULT_NUMBER_OF_EXPANDED_QUESTIONS = 0;

export const FAQSection = ({ sectionName, faqs }: FAQSectionProps) => {
  const [
    currentNumberOfExpandedQuestions,
    setCurrentNumberOfExpandedQuestions,
  ] = useState(DEFAULT_NUMBER_OF_EXPANDED_QUESTIONS);

  const handleOnClickAccordion = (expandedQuestionIds: string[]) => {
    const justTheIndices = expandedQuestionIds.map((id) =>
      Number(id.split('-#')[1]),
    );
    const newNumberOfExpandedQuestions = justTheIndices.length;

    if (
      !!newNumberOfExpandedQuestions &&
      newNumberOfExpandedQuestions > currentNumberOfExpandedQuestions
    ) {
      const lastIndex = -1;
      const [lastOpenAccordionIndex] = justTheIndices.slice(lastIndex);

      sendGa4FAQOpenClickEvent({
        sectionName,
        question: faqs[lastOpenAccordionIndex].question,
        answer: faqs[lastOpenAccordionIndex].answer,
      });
    }
    setCurrentNumberOfExpandedQuestions(newNumberOfExpandedQuestions);
  };

  return (
    <section>
      <h2 className={cx(typography.h2, styles.sectionHeading)}>
        {sectionName}
      </h2>
      <Accordion
        allowZeroExpanded
        allowMultipleExpanded
        className={styles.accordion}
        onChange={handleOnClickAccordion}
      >
        {faqs.map(({ answer, question }, index) => (
          <AccordionItem
            key={question}
            uuid={`${kebabCase(sectionName)}-#${index}`}
          >
            <AccordionItemHeading>
              <PeakAccordionButton>{question}</PeakAccordionButton>
            </AccordionItemHeading>
            <PeakAccordionItemPanel>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: answer }} />
            </PeakAccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};
