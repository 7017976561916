import type { MutableRefObject, ReactNode } from 'react';
import type { Id } from 'react-toastify';
import { toast } from 'react-toastify';
import { Icon } from '@components/icon/Icon';
import styles from './Toasts.scss';

const toastIconError = () => (
  <Icon name="alertCircle" className={styles.toastIconError} />
);

const toastIconInfo = () => (
  <Icon name="alertCircle" className={styles.toastIconError} />
);

const toastIconSuccess = () => (
  <Icon name="checkMark" className={styles.toastIconSuccess} />
);

const toastIconWarning = () => (
  <Icon name="alertCircle" className={styles.toastIconWarning} />
);

interface ToastArgs {
  message: ReactNode;
  onClose?: () => void;
}

export const errorToast = ({ message, onClose }: ToastArgs): Id =>
  toast.error(message, {
    autoClose: 5000,
    icon: toastIconError(),
    onClose,
    type: 'error',
    role: 'alert',
  });

export const infoToast = ({ message, onClose }: ToastArgs): Id =>
  toast.info(message, {
    autoClose: 5000,
    icon: toastIconInfo(),
    onClose,
    type: 'info',
    role: 'alert',
  });

export const successToast = ({ message, onClose }: ToastArgs): Id =>
  toast.success(message, {
    autoClose: 5000,
    icon: toastIconSuccess(),
    onClose,
    role: 'alert',
    type: 'success',
  });

export const warningToast = ({ message, onClose }: ToastArgs): Id =>
  toast.warning(message, {
    autoClose: 5000,
    icon: toastIconWarning(),
    onClose,
    role: 'alert',
    type: 'warning',
  });

type UpdateToastArgs = ToastArgs & { toastRef: MutableRefObject<Id> };

export const updateToast = ({
  message,
  onClose,
  toastRef,
}: UpdateToastArgs): void => {
  toast.update(toastRef.current, {
    autoClose: 5000,
    onClose,
    render: message,
    role: 'alert',
    type: 'success',
  });
};
