// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Address__container___MF08_{color:var(--white);padding-left:24px;text-indent:-24px}.Address__container___MF08_ .Address__icon___YXZcC{width:16px;height:16px;margin-right:8px;stroke:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/Address.scss"],"names":[],"mappings":"AAAA,4BACE,kBAAA,CACA,iBAAA,CACA,iBAAA,CAEA,mDACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Address__container___MF08_",
	"icon": "Address__icon___YXZcC"
};
export default ___CSS_LOADER_EXPORT___;
