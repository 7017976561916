import cx from 'classnames';
import type { ReactNode } from 'react';
import { WideView } from '@components/wideView/WideView';
import { Drawer } from './Drawer';
import { Footer } from './Footer';
import { Header } from './Header';
import styles from './Layout.scss';

export interface LayoutProps {
  children: ReactNode;
  className?: string;
  hideBorder?: boolean;
}

export const Layout = ({ children, className, hideBorder }: LayoutProps) => (
  <div className={cx(styles.container, className)}>
    <div>
      <Header hideBorder={hideBorder} />
      <div className={styles.mainContent}>
        <WideView narrowElement={<Drawer />} />
        {children}
      </div>
    </div>
    <Footer />
  </div>
);
