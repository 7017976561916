// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferDetails__container___DdhTt{border-bottom:1px solid var(--hairline);display:flex;flex-direction:column;gap:2rem;margin-bottom:2.5rem;padding-bottom:2.5rem}.OfferDetails__container___DdhTt h2{margin:0}.OfferDetails__container___DdhTt dl{display:grid;gap:2rem 1rem;grid-template-columns:max-content auto}.OfferDetails__container___DdhTt dl dt{color:var(--white70);font-size:inherit;white-space:nowrap}.OfferDetails__container___DdhTt dl dd{color:#fff;font-size:inherit;margin:0;text-align:right}.OfferDetails__container___DdhTt>div{display:flex;flex-direction:column;gap:1rem}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OfferDetails.scss"],"names":[],"mappings":"AAAA,iCACE,uCAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,oBAAA,CACA,qBAAA,CAEA,oCACE,QAAA,CAGF,oCACE,YAAA,CACA,aAAA,CACA,sCAAA,CAEA,uCACE,oBAAA,CACA,iBAAA,CACA,kBAAA,CAGF,uCACE,UAAA,CACA,iBAAA,CACA,QAAA,CACA,gBAAA,CAIJ,qCACE,YAAA,CACA,qBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OfferDetails__container___DdhTt"
};
export default ___CSS_LOADER_EXPORT___;
