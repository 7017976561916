import type { ApiResponse } from '@shared/types/apiHelpers';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useAuthModalContext } from '../context/AuthModalContext';
import { useUserContext } from '../context/UserContext';
import type { AuthenticatedUser } from './apiHelpers';
import { register } from './apiHelpers';
import { VerifyOTPModal } from './VerifyOTPModal';

export interface SignUpOTPModalProps {
  email: string;
  firstName: string;
  isTosAccepted: boolean;
  lastName: string;
  onAuthSuccess: () => void;
  phone: string;
}

export const SignUpOTPModal = ({
  email,
  firstName,
  isTosAccepted,
  lastName,
  onAuthSuccess,
  phone,
}: SignUpOTPModalProps) => {
  const { setUser, setIsAuthenticated } = useUserContext();
  const { closeModal } = useAuthModalContext();

  const onSubmit = async (phoneVerificationCode: string) => {
    const response: ApiResponse<AuthenticatedUser> = await register({
      email,
      firstName,
      isTosAccepted,
      lastName,
      phone,
      phoneVerificationCode,
    });

    if (isSuccessResponse(response)) {
      closeModal();
      setIsAuthenticated(true);
      setUser(response);
      onAuthSuccess();
    }

    return response;
  };

  return (
    <VerifyOTPModal
      label="Verify Phone Number"
      onSubmit={onSubmit}
      phone={phone}
      submitButtonLabel="Register"
    />
  );
};
