// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferCancellationPolicyNotice__container___rXuMe{padding:14px;margin-top:24px;border:1px solid var(--hairline);border-radius:8px;color:var(--white70);text-align:left}.OfferCancellationPolicyNotice__container___rXuMe h3{margin:0;font-size:16px;font-weight:500}.OfferCancellationPolicyNotice__container___rXuMe .OfferCancellationPolicyNotice__text___TjigY{margin-top:8px;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/OfferCancellationPolicyNotice.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CACA,eAAA,CACA,gCAAA,CACA,iBAAA,CACA,oBAAA,CACA,eAAA,CAEA,qDACE,QAAA,CACA,cAAA,CACA,eAAA,CAGF,+FACE,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OfferCancellationPolicyNotice__container___rXuMe",
	"text": "OfferCancellationPolicyNotice__text___TjigY"
};
export default ___CSS_LOADER_EXPORT___;
