import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import { useTimer } from '../hooks/useTimer';
import { OFFERS_OUT_PATH } from '../paths';
import { formatTimeRemaining } from '../utils/formatTimeRemaining';
import type * as apiHelpers from './apiHelpers';
import {
  sendGa4OfferUpdateStartEvent,
  sendGa4OfferWithdrawStartEvent,
} from './gaHelpers';
import styles from './OfferDetails.scss';
import { UpdateOfferModal } from './UpdateOfferModal';
import { WithdrawOfferModal } from './WithdrawOfferModal';

interface OfferDetailsProps {
  offer: apiHelpers.OfferDetails;
}

export const OfferDetails = ({ offer }: OfferDetailsProps) => {
  const navigate = useNavigate();
  const timeRemaining = useTimer(offer);
  const {
    isOpen: isWithdrawOfferModalOpen,
    open: openWithdrawOfferModal,
    close: closeWithdrawOfferModal,
  } = useIsOpen();
  const {
    isOpen: isUpdateOfferModalOpen,
    open: openUpdateOfferModal,
    close: closeUpdateOfferModal,
  } = useIsOpen();

  return (
    <>
      <section className={styles.container}>
        <h2 className={typography.h4} id="od-h">
          Offer Details
        </h2>
        <dl aria-labelledby="od-h" className={typography.t1} role="group">
          <dt aria-labelledby="od-ei-t" id="od-ei-t">
            Expires In
          </dt>
          <dd aria-labelledby="od-ei-d" id="od-ei-d">
            {formatTimeRemaining(timeRemaining)}
          </dd>
          <dt aria-labelledby="od-oa-t" id="od-oa-t">
            Offer Amount
          </dt>
          <dd aria-labelledby="od-oa-d" id="od-oa-d">
            {centsToWholeDollar(offer.price)}
          </dd>
          <dt aria-labelledby="od-pt-t" id="od-pt-t">
            Payment Type
          </dt>
          <dd aria-labelledby="od-pt-d" id="od-pt-d">
            {offer.transaction && offer.transaction.paymentMethod}
          </dd>
        </dl>
        <div>
          <Button
            label="Update Offer"
            onClick={() => {
              sendGa4OfferUpdateStartEvent({
                date: offer.date,
                offerAmount: offer.price,
                publicName: offer.listing.publicName,
                restaurantName: offer.restaurant.name,
                time: offer.time,
              });
              openUpdateOfferModal();
            }}
            variant={ButtonVariants.Primary}
          />
          <Button
            label="Withdraw Offer"
            onClick={() => {
              sendGa4OfferWithdrawStartEvent({
                date: offer.date,
                offerAmount: offer.price,
                publicName: offer.listing.publicName,
                restaurantName: offer.restaurant.name,
                time: offer.time,
              });
              openWithdrawOfferModal();
            }}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </section>
      <UpdateOfferModal
        offer={offer}
        onClose={closeUpdateOfferModal}
        isOpen={isUpdateOfferModalOpen}
      />
      <WithdrawOfferModal
        closeModal={closeWithdrawOfferModal}
        offer={offer}
        onSubmit={() => navigate(OFFERS_OUT_PATH)}
        isOpen={isWithdrawOfferModalOpen}
      />
    </>
  );
};
