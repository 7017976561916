import { isValidEmail } from '@shared/utils/emailValidator';

export const isWholeNumberRule =
  (fieldName: string) => (value: string | number) =>
    Number.isInteger(Number(value)) || `${fieldName} must be a whole number`;

export const isSlugRule = (fieldName: string) => (value?: string) => {
  if (!value) {
    return true;
  }

  return (
    /^[a-z0-9-]+$/.test(value) ||
    `${fieldName} must only contain lower case letters, numbers and hyphens`
  );
};

export const isWebsiteUrlRule = (fieldName: string) => (value?: string) => {
  if (!value) {
    return true;
  }

  return (
    /^(http|https):\/\/(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
      value,
    ) || `${fieldName} must be a valid URL`
  );
};

export const isEmailRule = (fieldName: string) => (value?: string) => {
  if (!value) {
    return true;
  }
  return isValidEmail(value) || `${fieldName} must be a valid email address`;
};

export const isValidEmailOrWhiteSpaceRule = (value = '') => {
  const trimmedValue = value.trim();
  if (!trimmedValue) {
    return true;
  }

  return isValidEmail(trimmedValue) || 'Must be a valid email address';
};
