// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationAttributesGrid__container___wCITB{display:flex;flex-wrap:wrap;align-items:center;column-gap:8px}.ReservationAttributesGrid__attribute___WiZzH{padding:8px 0;column-gap:8px;display:flex;align-items:center;flex-basis:calc(50% - 4px)}.ReservationAttributesGrid__attribute___WiZzH .ReservationAttributesGrid__table___lI721{text-transform:capitalize}.ReservationAttributesGrid__attribute___WiZzH .ReservationAttributesGrid__icon___rixBu{stroke:var(--primary200);width:24px;min-width:24px}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationAttributesGrid.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA,CAGF,8CACE,aAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CAEA,wFACE,yBAAA,CAGF,uFACE,wBAAA,CACA,UAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationAttributesGrid__container___wCITB",
	"attribute": "ReservationAttributesGrid__attribute___WiZzH",
	"table": "ReservationAttributesGrid__table___lI721",
	"icon": "ReservationAttributesGrid__icon___rixBu"
};
export default ___CSS_LOADER_EXPORT___;
