import cx from 'classnames';
import { type EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { useEffect, useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import typography from '~styles/typography.scss';
import { RESTAURANT_DEMO_PATH } from '../paths';
import { InfoTabContentNarrow } from './InfoTabContentNarrow';
import styles from './InfoTabsNarrow.scss';
import { Tab } from './utils';

export const InfoTabsNarrow = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.ForRestaurants);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onInit = (api: EmblaCarouselType) => {
    setScrollSnaps(api.scrollSnapList());
    setSelectedIndex(0);
  };

  const onSelect = (api: EmblaCarouselType) => {
    setSelectedIndex(api.selectedScrollSnap());
  };

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit).on('select', onSelect);
  }, [emblaApi]);

  const handleOnDotClick = (index: number) => {
    if (!emblaApi) return;
    emblaApi.scrollTo(index);
  };

  const handleOnTabClick = (tab: Tab) => () => {
    if (!emblaApi) return;

    emblaApi.scrollTo(0);
    setSelectedIndex(0);
    setActiveTab(tab);
  };

  return (
    <section className={styles.section}>
      <h2 className={typography.d3}>One Platform Built for Two Worlds</h2>
      <div className={styles.buttonContainer}>
        <Button
          label="For Restaurants"
          variant={
            activeTab === Tab.ForRestaurants
              ? ButtonVariants.Primary
              : ButtonVariants.Tertiary
          }
          onClick={handleOnTabClick(Tab.ForRestaurants)}
        />
        <Button
          label="For Diners"
          variant={
            activeTab === Tab.ForDiners
              ? ButtonVariants.Primary
              : ButtonVariants.Tertiary
          }
          onClick={handleOnTabClick(Tab.ForDiners)}
        />
      </div>
      <div className={styles.infoCarousel} ref={emblaRef}>
        <div className={styles.container}>
          <InfoTabContentNarrow activeTab={activeTab} />
        </div>
      </div>
      <div className={styles.dotContainer}>
        {scrollSnaps.map((_, index) => (
          <button
            className={cx({
              [styles.active]: index === selectedIndex,
            })}
            aria-label={`Scroll to slide ${index + 1}`}
            key={_}
            onClick={() => handleOnDotClick(index)}
          />
        ))}
      </div>
      <LinkStyledAsButton
        className={styles.button}
        label="Use Peak for Your Restaurant"
        to={RESTAURANT_DEMO_PATH}
        variant={ButtonVariants.Tertiary}
      />
    </section>
  );
};
