import GoldTableIllustration from '@assets/images/gold-table-illustration.svg';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import typography from '~styles/typography.scss';
import { RESTAURANT_DEMO_PATH } from '../paths';
import styles from './FinalCallToAction.scss';

export const FinalCallToAction = () => (
  <section className={styles.section}>
    <div className={styles.content}>
      <div>
        <h2 className={typography.d3}>Set the Table For Great Experiences</h2>
        <p>
          Have guests indulge in the pinnacle of dining. Whether you’re a
          restaurant wishing to streamline operations or a customer looking for
          exclusive tables, unlock new possibilities with us at Peak.
        </p>
        <LinkStyledAsButton
          label="Book a Demo"
          to={RESTAURANT_DEMO_PATH}
          variant={ButtonVariants.Primary}
        />
      </div>
      <GoldTableIllustration className={styles.goldTableIllustration} />
    </div>
  </section>
);
