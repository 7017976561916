// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationDetailsPanel__sellItContainer___AKHmY{border-bottom:1px solid var(--hairline);padding-bottom:40px;margin-bottom:40px}.ReservationDetailsPanel__sellIt___YaT_F{margin-top:0;margin-bottom:24px}.ReservationDetailsPanel__card___XI7kR{background-color:var(--panelBlue);border-radius:10px;margin-bottom:8px}.ReservationDetailsPanel__cancelButton___xtz5e{border:1px solid var(--pink);color:var(--pink);margin-top:2rem;min-height:2.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ReservationDetailsPanel.scss"],"names":[],"mappings":"AAAA,kDACE,uCAAA,CACA,mBAAA,CACA,kBAAA,CAGF,yCACE,YAAA,CACA,kBAAA,CAGF,uCACE,iCAAA,CACA,kBAAA,CACA,iBAAA,CAGF,+CACE,4BAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellItContainer": "ReservationDetailsPanel__sellItContainer___AKHmY",
	"sellIt": "ReservationDetailsPanel__sellIt___YaT_F",
	"card": "ReservationDetailsPanel__card___XI7kR",
	"cancelButton": "ReservationDetailsPanel__cancelButton___xtz5e"
};
export default ___CSS_LOADER_EXPORT___;
