// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toasts__toastIconError___hpEjL{stroke:var(--red300)}.Toasts__toastIconSuccess___AsnA2{stroke:var(--green300)}.Toasts__toastIconWarning___N_kdI{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/shared/components/toasts/Toasts.scss"],"names":[],"mappings":"AAAA,gCACE,oBAAA,CAGF,kCACE,sBAAA,CAGF,kCACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastIconError": "Toasts__toastIconError___hpEjL",
	"toastIconSuccess": "Toasts__toastIconSuccess___AsnA2",
	"toastIconWarning": "Toasts__toastIconWarning___N_kdI"
};
export default ___CSS_LOADER_EXPORT___;
