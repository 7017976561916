import { Link } from 'react-router-dom';
import { Icon } from '@components/icon/Icon';
import { DeprecatedNarrowView } from '@components/narrowView/DeprecatedNarrowView';
import { Navigation } from '../navigation/Navigation';
import { NavigationMobile } from '../navigation/NavigationMobile';
import { LANDING_PATH } from '../paths';
import styles from './Header.scss';

interface HeaderProps {
  hideBorder?: boolean;
}

export const DeprecatedHeader = ({ hideBorder = false }: HeaderProps) => {
  const headerStyle = hideBorder ? '' : styles.headerBottomBorder;

  return (
    <div className={styles.headerContainer}>
      <header className={headerStyle}>
        <Link
          aria-label="Peak home page"
          className={styles.logo}
          to={LANDING_PATH}
        >
          <Icon name="logo" />
        </Link>
        <nav aria-label="primary" className={styles.nav}>
          <DeprecatedNarrowView
            element={<NavigationMobile />}
            fallback={<Navigation />}
          />
        </nav>
      </header>
    </div>
  );
};
