export interface Faq {
  question: string;
  answer: string;
}

export default [
  {
    sectionName: 'General',
    faqs: [
      {
        question: 'How does it work?',
        answer: `<ol>
                  <li>Visit a restaurant's page and view its availability.</li>
                  <li>Choose a specific 'availability' - a day/time and a set of possible tables (a table type and location) or a specific table.</li>
                  <li>If someone already owns a reservation for that availability, you can choose to 'make an offer' on that reservation.</li>
                  <li>If the reservation has not been purchased by another customer, or if it has been listed by its owner, you can choose to buy it.</li>
                  <li>Once you have purchased a reservation or made an offer, you will receive a confirmation via text message.</li>
                </ol>`,
      },
      {
        question: 'What time zone are the reservations for?',
        answer:
          "All reservation times are displayed in the restaurant's local time zone.",
      },
      {
        question: 'What is Peak Reservations?',
        answer:
          'Peak Reservations is a restaurant reservation system that allows users to book and pay for individual tables at a restaurant. In addition, Peak includes a secondary market where people can make offers and listings to resell or buy reservations that have already been purchased.',
      },
      {
        question: 'How do I view available tables at a restaurant on Peak?',
        answer:
          'After selecting a restaurant, you will be taken to the availability page, where you can filter available reservations by guest count, date and time. You will also be able to view the restaurant’s floorplan, enabling you to select the premier seat for you.',
      },
    ],
  },
  {
    sectionName: 'Account',
    faqs: [
      {
        question: 'How do I create an Account?',
        answer: `
                <p>To make a reservation or an offer on Peak, you need to create an account.</p>
                <ol>
                  <li>To create an account, you need a mobile number.</li>
                  <li>Click the 'Login' button and then click on 'Create Account.'</li>
                  <li>Once you have created an account, you can use it to buy reservations or make offers.</li>
                </ol>`,
      },
      {
        question: 'Where can I view my Offers In/Out?',
        answer: `Go to your <a target="_blank" href="/offers">Offers</a> `,
      },
      {
        question: 'How do I view my Reservations?',
        answer: `Go to your <a target="_blank" href="/reservations">Reservations</a>`,
      },
      {
        question: 'How do I set a List Price?',
        answer: `<ol>
                  <li>Go to your <a target="_blank" href="/reservations">Reservations</a></li>
                  <li>Click ‘List Reservation’ to set a sales price.</li>
                </ol>`,
      },
      {
        question: 'How do I cancel a reservation?',
        answer:
          'You must contact the restaurant directly to cancel a reservation.',
      },
      {
        question: 'How do I reschedule a reservation?',
        answer:
          'You must contact the restaurant, cancel the reservation, and rebook.',
      },
      {
        question: 'What if I have special requests?',
        answer:
          'You must call the restaurant directly to let them know of any accommodations.',
      },
      {
        question:
          'What if I made a reservation through Peak but never signed in?',
        answer:
          'Use the phone number you gave the restaurant when booking the reservation to see past and future reservation details.',
      },
      {
        question:
          'Does purchasing a reservation or making an offer on Peak Reservations count towards my bill at the restaurant?',
        answer:
          'No, when you purchase a reservation or make an offer on Peak Reservations, you are paying for the table only. All other charges from the restaurant, such as food, drinks, tax, and gratuity, are separate and will need to be paid directly to the restaurant at the time of your visit.',
      },
      {
        question:
          'Is it safe to buy and sell reservations on Peak Reservations?',
        answer:
          'Peak Reservations takes security and safety seriously and has measures in place to protect users.',
      },
    ],
  },
  {
    sectionName: 'Reservations',
    faqs: [
      {
        question: 'How do I select a reservation?',
        answer: `
                <p>After selecting your desired party size, date, and time, you may:</p>
                <ol>
                  <li>Click a table directly to filter even further.</li>
                  <li>Scroll through the list of potential reservations on the right side (clicking a reservation will highlight its associated table on the floor plan).</li>
                </ol>`,
      },
      {
        question:
          'Do I need an account to buy a reservation or make an offer on Peak Reservations?',
        answer:
          'Yes, you need an account to buy a reservation or make an offer on Peak.',
      },
      {
        question:
          'Is there a maximum number of reservations a customer can have?',
        answer:
          'You may only have a maximum of 2 upcoming reservations every 2 hours at a time.',
      },
    ],
  },
  {
    sectionName: 'Offers and Listings',
    faqs: [
      {
        question: 'How can I sell reservation?',
        answer:
          'You may sell your reservation by going to the <a target="_blank" href="/reservations">Reservations Tab</a>, clicking on the specific reservation you wish to sell, and then inputting your desired sale price under “list reservation.',
      },
      {
        question: 'How do I retrieve funds after selling a reservation?',
        answer:
          'Please check your account settings to connect your bank account and withdraw your balance.',
      },
      {
        question:
          'What happens if someone already reserved my desired table (at the time I need)?',
        answer: 'You can “Make an Offer” on that reservation.',
      },
      {
        question: 'How do I make an offer?',
        answer: `When viewing a specific reservation that has already been purchased by another user but has not been listed, rather than a price to buy the reservation directly (‘Buy Now’) you will see an option to ‘Make an Offer.'
          <br>
          <br>
          The amount of your offer is put on hold for up to 7 days, and no money is taken from your account until and unless the offer is accepted.`,
      },
      {
        question: 'What happens if my offer is accepted?',
        answer:
          'You will receive a text notifying that your offer has been accepted, and you may log in at any time to view your reservation.',
      },
      {
        question: 'What happens if my offer is not accepted?',
        answer:
          'The hold on your funds for that offer amount will be released 7 days after making the offer.',
      },
      {
        question: 'Is there a maximum number of offers a customer can have?',
        answer:
          'You can only own up to two reservations every two hours so depending on how many reservations you own it may limit you on making more offers around that time period.',
      },
      {
        question:
          'Can I make an offer on a reservation if it is within 30 minutes of the current time?',
        answer:
          'No offer can be made if the reservation is within 30 minutes of the current time.',
      },
    ],
  },
];
