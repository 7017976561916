import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import { useUserContext } from '../context/UserContext';

export const IsAuthenticated = () => {
  const { openModal } = useAuthModalContext();
  const { isAuthenticated, isLoading } = useUserContext();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      openModal(AuthModalTypes.SIGN_IN);
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return null;
  }

  return isAuthenticated ? <Outlet /> : null;
};
