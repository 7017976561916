import API from '../api/apiClient';

interface PayoutLink {
  linkToken: string;
}

export const getPayoutLinkToken = async (): Promise<PayoutLink> => {
  const response = await API.get('/payouts/link-token');
  const { linkToken } = await response.json();
  return linkToken;
};

export const createLinkedAccount = async (
  publicToken: string,
  accountId: string,
) =>
  API.post('/payouts/linked-account', {
    publicToken,
    accountId,
  });
