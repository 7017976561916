import typography from '@shared/styles/typography.scss';
import styles from './PaymentMethodsPage.scss';
import { SavedSettingsPaymentMethod } from './SavedSettingsPaymentMethod';
import { useSettingsPaymentMethods } from './useSettingsPaymentMethods';

export const PaymentMethodsPage = () => {
  const { handleOnDeletePaymentMethod, paymentMethods } =
    useSettingsPaymentMethods();

  return (
    <div className={styles.container}>
      <h2 className={typography.h4} id="pmTitle">
        Payment Methods
      </h2>
      {paymentMethods.length ? (
        <ul aria-labelledby="pmTitle">
          {paymentMethods.map((pm) => (
            <SavedSettingsPaymentMethod
              handleOnDeletePaymentMethod={handleOnDeletePaymentMethod}
              key={pm.id}
              paymentMethod={pm}
            />
          ))}
        </ul>
      ) : (
        <p className={typography.t1}>You have no saved payment methods.</p>
      )}
    </div>
  );
};
