import cx from 'classnames';
import { useState } from 'react';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { getS3AssetPath } from '@shared/utils/getS3AssetPath';
import typography from '~styles/typography.scss';
import { RESTAURANT_DEMO_PATH } from '../paths';
import styles from './Hero.scss';

export const Hero = () => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const heroClassNames = cx({
    [styles.image]: true,
    [styles.loaded]: !isImageLoading,
  });

  return (
    <section className={styles.section}>
      <img
        alt="hero banner"
        className={heroClassNames}
        loading="lazy"
        onLoad={() => setIsImageLoading(false)}
        src={getS3AssetPath('discover-hero.jpg')}
      />
      <div className={styles.content}>
        <div className={styles.copy}>
          <h1 className={typography.d1}>The Best Seat in the House</h1>
          <p className={styles.heroSubtitle}>
            Explore our premier dining platform where restaurants thrive, diners
            discover, and unforgettable experiences are made—all in one free
            service.
          </p>
          <LinkStyledAsButton
            label="See it in Action"
            to={RESTAURANT_DEMO_PATH}
            variant={ButtonVariants.Primary}
          />
        </div>
      </div>
    </section>
  );
};
