// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckoutForm__bookAndPay___vL8ub{margin-top:16px;width:100%}.CheckoutForm__cancel____fX3O{margin-top:16px;width:100%}.CheckoutForm__offerNotice___vWSkA{color:var(--white);margin-top:16px;margin-bottom:32px}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/CheckoutForm.scss"],"names":[],"mappings":"AAAA,kCACE,eAAA,CACA,UAAA,CAGF,8BACE,eAAA,CACA,UAAA,CAGF,mCACE,kBAAA,CACA,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookAndPay": "CheckoutForm__bookAndPay___vL8ub",
	"cancel": "CheckoutForm__cancel____fX3O",
	"offerNotice": "CheckoutForm__offerNotice___vWSkA"
};
export default ___CSS_LOADER_EXPORT___;
