// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AcceptTopOfferModal__container___sNMNH{align-items:center;display:flex;flex-direction:column;margin-top:3rem;width:100%}@media only screen and (min-width: 768px){.AcceptTopOfferModal__container___sNMNH{margin-top:0;padding:2rem 0}}.AcceptTopOfferModal__price___lW2yZ{margin-bottom:.625rem;margin-top:0}.AcceptTopOfferModal__expire___pS3km{margin-bottom:4rem;margin-top:0}.AcceptTopOfferModal__error___hU16F{color:var(--pink);margin-top:1rem}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/AcceptTopOfferModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,wCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,UAAA,CCyBA,0CD9BF,wCAQI,YAAA,CACA,cAAA,CAAA,CAIJ,oCACE,qBAAA,CACA,YAAA,CAGF,qCACE,kBAAA,CACA,YAAA,CAGF,oCACE,iBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AcceptTopOfferModal__container___sNMNH",
	"price": "AcceptTopOfferModal__price___lW2yZ",
	"expire": "AcceptTopOfferModal__expire___pS3km",
	"error": "AcceptTopOfferModal__error___hU16F"
};
export default ___CSS_LOADER_EXPORT___;
