import cx from 'classnames';
import type { ReactNode } from 'react';
import { Link, type To } from 'react-router-dom';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './RestaurantAboutLink.scss';

interface RestaurantAboutLinkProps {
  children: ReactNode;
  iconName: AnyIcon;
  to: To;
}

export const RestaurantAboutLink = ({
  children,
  iconName,
  to,
}: RestaurantAboutLinkProps) => (
  <Link
    to={to}
    className={cx(typography.c3, styles.linkText)}
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    <Icon name={iconName} className={styles.icon} />
    {children}
  </Link>
);
