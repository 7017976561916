interface GetOptions {
  signal?: AbortSignal;
}

/** @deprecated - import { api } from
 * 'web/src/webReservations/api' instead.
 *
 * See [SC-10022 New api fetch utils](https://github.com/Peak-Reservations/reservations/pull/1635)
 * for examples of the new pattern.
 */
export default {
  get: async (path: string, { signal }: GetOptions = {}) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'GET',
      credentials: 'include',
      signal,
    }),

  post: async <TBody>(path: string, body?: TBody) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  delete: async (path: string) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  put: async <TBody>(path: string, body?: TBody) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};
