import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import type { ChangeEventHandler } from 'react';
import { CardElement } from '@components/cardElement/CardElement';
import type { PaymentMethod } from './apiHelpers';
import styles from './PaymentMethods.scss';
import { SavedPaymentMethod } from './SavedPaymentMethod';

export interface PaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  selectedPaymentMethodId?: string;
  setupFutureCardUsage: boolean;
  handleOnChangePaymentMethod: ChangeEventHandler<HTMLInputElement>;
  handleOnChangeName: ChangeEventHandler<HTMLInputElement>;
  handleOnChangeCardElement: (event: StripeCardElementChangeEvent) => void;
  handleOnChangeSaveCard: () => void;
  handleOnDeletePaymentMethod: (id: string) => void;
}

export const PaymentMethods = ({
  handleOnChangePaymentMethod,
  paymentMethods,
  selectedPaymentMethodId,
  setupFutureCardUsage,
  handleOnChangeName,
  handleOnChangeCardElement,
  handleOnChangeSaveCard,
  handleOnDeletePaymentMethod,
}: PaymentMethodsProps) => (
  <>
    <div role="radiogroup">
      {paymentMethods.map((savedPaymentMethod) => (
        <SavedPaymentMethod
          key={savedPaymentMethod.id}
          onChange={handleOnChangePaymentMethod}
          onDelete={() => handleOnDeletePaymentMethod(savedPaymentMethod.id)}
          savedPaymentMethod={savedPaymentMethod}
          selectedPaymentMethodId={selectedPaymentMethodId}
        />
      ))}
      <div className={styles.newPaymentMethodContainer}>
        <label htmlFor="newPaymentMethod">
          <input
            checked={!selectedPaymentMethodId}
            id="newPaymentMethod"
            name="paymentMethod"
            onChange={handleOnChangePaymentMethod}
            type="radio"
            value=""
          />
          Add Credit Card
        </label>
      </div>
    </div>
    {!selectedPaymentMethodId && (
      <>
        <input
          className={styles.name}
          data-testid="full-name-input"
          id="nameInput"
          onChange={handleOnChangeName}
          placeholder="Full Name"
        />
        <CardElement onChange={handleOnChangeCardElement} />
        <label className={styles.saveCard} htmlFor="saveCard">
          <input
            checked={setupFutureCardUsage}
            id="saveCard"
            onChange={handleOnChangeSaveCard}
            type="checkbox"
          />
          Save Card to My Account
        </label>
      </>
    )}
  </>
);
