import cx from 'classnames';
import type { ReactNode } from 'react';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { ImageLoader } from './ImageLoader';
import styles from './RestaurantHero.scss';

export interface RestaurantHeroProps {
  restaurant: {
    address: string;
    id: string;
    name: string;
  };
  children?: ReactNode;
  navBar?: ReactNode;
}

export const RestaurantHero = ({
  restaurant,
  children,
  navBar,
}: RestaurantHeroProps) => (
  <section className={styles.hero} data-testid="restaurant-hero-banner">
    <ImageLoader
      className={styles.restaurantImage}
      src={`${process.env.RESTAURANT_IMAGE_BASE_URL}/${restaurant.id}/main.jpg`}
    />
    <div className={styles.restaurantInfo}>
      <h1 className={cx(styles.name, typography.d3)}>{restaurant.name}</h1>
      <div className={styles.address}>
        <Icon className={styles.icon} name="locationArrow" />
        {restaurant.address}
      </div>
      {children}
    </div>
    {navBar && <div className={styles.navBar}>{navBar}</div>}
  </section>
);
