import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { EmptyState } from '../components/EmptyState';
import type { Offer } from './apiHelpers';
import { sendGa4OffersOutViewEvent } from './gaHelpers';
import { OfferOutCard } from './OfferOutCard';
import styles from './OfferTabs.scss';

export const OffersOutPage = () => {
  const offersOut = useLoaderData() as Offer[];

  useDocumentTitle('My Outgoing Offers | Peak Reservations');

  useEffect(() => {
    sendGa4OffersOutViewEvent();
  }, []);

  if (!offersOut.length) {
    return (
      <EmptyState
        title="Snag your favorite table even when it’s off the market."
        subtitle="Make offers on reservations that have already been booked by someone else."
      />
    );
  }

  return (
    <ul className={styles.container}>
      {offersOut.map((offer) => (
        <OfferOutCard key={JSON.stringify(offer)} offer={offer} />
      ))}
    </ul>
  );
};
