import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';

interface EmailSupportProps {
  label?: string;
  className?: string;
  testId?: string;
}

export const EmailSupport = ({
  label = process.env.SUPPORT_EMAIL || 'Support email is missing',
  className,
  testId,
}: EmailSupportProps) => (
  <LinkStyledAsButton
    className={className}
    data-testid={testId}
    rel="noopener noreferrer"
    target="_blank"
    to={process.env.SUPPORT_EMAIL ? `mailto:${process.env.SUPPORT_EMAIL}` : '#'}
    variant={ButtonVariants.Tertiary}
    label={label}
  />
);
