import cx from 'classnames';
import type { ReactNode } from 'react';
import { DeprecatedNarrowView } from '@components/narrowView/DeprecatedNarrowView';
import { DeprecatedHeader } from './DeprecatedHeader';
import { Drawer } from './Drawer';
import { Footer } from './Footer';
import styles from './Layout.scss';

export interface LayoutProps {
  children: ReactNode;
  className?: string;
  hideBorder?: boolean;
}

/**
 * @deprecated This component switches at a buggy breakpoint. Use `Layout` instead.
 */
export const DeprecatedLayout = ({
  children,
  className,
  hideBorder,
}: LayoutProps) => (
  <div className={cx(styles.container, className)}>
    <div>
      <DeprecatedHeader hideBorder={hideBorder} />
      <div className={styles.mainContent}>
        <DeprecatedNarrowView element={<Drawer />} />
        {children}
      </div>
    </div>
    <Footer />
  </div>
);
