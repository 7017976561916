import { useEffect, useState } from 'react';
import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { useRestaurantContext } from '../context/RestaurantContext';
import type { RestaurantDetails } from '../restaurant/apiHelpers';
import { getRestaurantDetailsById } from '../restaurant/apiHelpers';
import { useError } from './useError';

type RestaurantDetailsOrEmptyObject = RestaurantDetails | Record<string, never>;

export const useRestaurantDetails = (
  restaurantId: string,
): {
  restaurantDetails: RestaurantDetailsOrEmptyObject;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const setError = useError();
  const { restaurantDetails, setRestaurantDetails } = useRestaurantContext();

  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      try {
        const response = await getRestaurantDetailsById(restaurantId);

        if (isErrorResponse(response)) {
          throw createResponseError(response);
        } else {
          setRestaurantDetails(response);
        }

        setIsLoading(false);
      } catch (error: unknown) {
        setError(error);
      }
    };
    fetchRestaurantDetails();
  }, []);

  return { restaurantDetails, isLoading };
};
