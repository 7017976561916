// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnregisteredUserLoginModal__info___j4RdD{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/webReservations/auth/UnregisteredUserLoginModal.scss"],"names":[],"mappings":"AAAA,0CACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "UnregisteredUserLoginModal__info___j4RdD"
};
export default ___CSS_LOADER_EXPORT___;
