import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './ReservationStatus.scss';
import { ReservationStatusType } from './statusHelpers';

interface ReservationStatusProps {
  status: string;
}

export const ReservationStatus = ({ status }: ReservationStatusProps) => {
  const statusClassNames = cx({
    [typography.t2]: true,
    [styles.confirmed]: status === ReservationStatusType.Confirmed,
    [styles.completed]: status === ReservationStatusType.Completed,
    [styles.canceled]: status === ReservationStatusType.Canceled,
    [styles.resold]: status === ReservationStatusType.Sold,
  });

  return (
    <div data-testid="reservation-status" className={statusClassNames}>
      {status}
    </div>
  );
};
