import { Input } from '@mui/base/Input';
import cx from 'classnames';
import type {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormInput.scss';
import { FormTooltip } from './FormTooltip';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  type: string;
  variant?: LayoutVariant;
  autoComplete?: 'on' | 'off';
  ignoreOnePass?: boolean;
  onFocus?: () => void;
}

export const ControlledFormInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  defaultValue,
  disabled,
  label,
  name,
  placeholder,
  rules,
  tooltipText,
  type,
  variant = LayoutVariant.Vertical,
  autoComplete = 'off',
  ignoreOnePass = true,
  onFocus,
}: ControlledFormInputProps<T, Name>) => {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    rules: {
      ...rules,
      validate: {
        ...rules?.validate,
      },
    },
  });

  return (
    <div
      className={cx(
        {
          [styles.fieldContainer]: true,
          [styles[variant]]: true,
        },
        className,
      )}
    >
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.inputContainer}>
        <Input
          aria-errormessage={`${name}-error`}
          aria-invalid={!!error}
          autoComplete={autoComplete}
          disabled={disabled}
          id={name}
          onChange={onChange}
          onWheel={() => (document.activeElement as HTMLInputElement).blur()}
          placeholder={placeholder}
          slotProps={{
            input: {
              ref,
              'aria-errormessage': `${name}-error`,
              'aria-invalid': !!error,
              // @ts-expect-error MUI base is not ready for 1pass attributes
              'data-1p-ignore': ignoreOnePass,
              'data-lpignore': ignoreOnePass,
              className: cx({
                [typography.t1]: true,
                [styles.inputError]: !!error,
              }),
              onFocus: (e) => {
                onFocus?.();
                if (e.target.type === 'date') {
                  try {
                    e.target.showPicker?.();
                  } catch (onFocusError) {
                    if (
                      onFocusError instanceof DOMException &&
                      onFocusError.name === 'NotAllowedError'
                    ) {
                      /* Ignore the NotAllowedError.
                       *
                       * Me and my bad, bad homies
                       * Doin' bad, bad things
                       * But it feels so good
                       *
                       * Source: pH-1, “Mr. Bad” (feat. Woo), 2022
                       * */
                    } else {
                      throw onFocusError;
                    }
                  }
                }
              },
            },
          }}
          type={type === 'email' ? 'text' : type}
          value={value || ''}
        />
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};
