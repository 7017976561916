// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTabsWide__section___IOFDl{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:3rem 2.5rem;row-gap:1rem;width:100%}.InfoTabsWide__section___IOFDl h2{text-align:center;margin:0 0 3rem}.InfoTabsWide__section___IOFDl .InfoTabsWide__buttonContainer___Ofdif{display:flex;flex-direction:row;margin-bottom:2rem;column-gap:1rem}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/InfoTabsWide.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,mBAAA,CACA,YAAA,CACA,UAAA,CAEA,kCACE,iBAAA,CACA,eAAA,CAGF,sEACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "InfoTabsWide__section___IOFDl",
	"buttonContainer": "InfoTabsWide__buttonContainer___Ofdif"
};
export default ___CSS_LOADER_EXPORT___;
