import cx from 'classnames';
import { useState } from 'react';
import styles from './ImageLoader.scss';

interface ImageLoaderProps {
  alt?: string;
  className?: string;
  src: string;
}

export const ImageLoader = ({
  alt = '',
  className = '',
  src,
}: ImageLoaderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoadError, setImageLoadError] = useState(false);

  const imageClassNames = cx({
    [className]: true,
    [styles.image]: true,
    [styles.imageLoaded]: !isLoading,
  });

  return imageLoadError ? (
    <div className={styles.defaultImage} data-testid="default-image" />
  ) : (
    <img
      alt={alt}
      className={imageClassNames}
      loading="lazy"
      onError={() => setImageLoadError(true)}
      onLoad={() => setIsLoading(false)}
      src={src}
    />
  );
};
