// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Accordion__accordion___BFTxe .Accordion__expandedPanel___zhf1b{background-color:var(--primary300);border-radius:4px;padding:40px 32px}.Accordion__accordionButton___Ff8Mh{display:flex;justify-content:space-between;margin-top:16px;padding:8px 0}.Accordion__accordionButton___Ff8Mh .Accordion__chevron___osDb2{height:24px;stroke:var(--accent300);transform:rotate(-90deg);width:24px}.Accordion__accordionButton___Ff8Mh[aria-selected=true] .Accordion__chevron___osDb2,.Accordion__accordionButton___Ff8Mh[aria-expanded=true] .Accordion__chevron___osDb2{transform:rotate(90deg)}.Accordion__accordionPanel___P9QmM{color:var(--white70);line-height:1.5rem;max-width:54em}.Accordion__accordionPanel___P9QmM a{color:inherit;text-decoration:underline}.Accordion__accordionPanel___P9QmM a:hover{color:var(--white)}", "",{"version":3,"sources":["webpack://./src/shared/components/accordion/Accordion.scss"],"names":[],"mappings":"AACE,gEACE,kCAAA,CACA,iBAAA,CACA,iBAAA,CAIJ,oCACE,YAAA,CACA,6BAAA,CACA,eAAA,CACA,aAAA,CAEA,gEACE,WAAA,CACA,uBAAA,CACA,wBAAA,CACA,UAAA,CAGF,wKAEE,uBAAA,CAIJ,mCACE,oBAAA,CACA,kBAAA,CACA,cAAA,CAEA,qCACE,aAAA,CACA,yBAAA,CAEA,2CACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "Accordion__accordion___BFTxe",
	"expandedPanel": "Accordion__expandedPanel___zhf1b",
	"accordionButton": "Accordion__accordionButton___Ff8Mh",
	"chevron": "Accordion__chevron___osDb2",
	"accordionPanel": "Accordion__accordionPanel___P9QmM"
};
export default ___CSS_LOADER_EXPORT___;
