import cx from 'classnames';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Logo from '@assets/icons/logo.svg';
import { Button, ButtonVariants } from '@components/button/Button';
import { LANDING_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import { sendEmailVerification } from './apiHelpers';
import styles from './EmailVerification.scss';

export const EmailVerificationFailPage = () => {
  const [isResent, setIsResent] = useState(false);
  const [searchParams] = useSearchParams();

  const handleClick = async () => {
    const email = searchParams.get('email');
    if (!email) {
      return;
    }
    await sendEmailVerification(email);
    setIsResent(true);
  };

  return (
    <main className={styles.container}>
      <Link aria-label="Peak home page" to={LANDING_PATH}>
        <Logo className={styles.logo} role="presentation" />
      </Link>
      <h1 className={typography.d3}>Oops!</h1>
      <p className={typography.c1}>
        Your email verification link has expired. Please click the “Resend”
        button to receive a new verification&nbsp;email.
      </p>
      <Button
        className={styles.resendButton}
        isDisabled={isResent}
        label="Resend"
        onClick={handleClick}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Primary}
      />
      <p
        className={cx(styles.statusMessage, isResent && styles.showStatus)}
        role="status"
      >
        Re-sent. Please check your email for updates from&nbsp;Peak!
      </p>
    </main>
  );
};
