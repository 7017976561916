import ReactGA from 'react-ga4';

export const initGoogleAnalytics = (): void => {
  const gaMeasurementID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID;

  if (gaMeasurementID) {
    ReactGA.initialize(gaMeasurementID, {
      gtagOptions: {
        send_page_view: false,
      },
    });
  }
};

export const initGoogleAnalyticsAdmin = (): void => {
  const gaMeasurementID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID;

  if (gaMeasurementID) {
    ReactGA.initialize(gaMeasurementID);
  }
};
