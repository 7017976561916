import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { RestaurantDetails } from 'webReservations/restaurant/apiHelpers';

export interface RestaurantContextState {
  restaurantDetails: RestaurantDetails;
  setRestaurantDetails: Dispatch<SetStateAction<RestaurantDetails>>;
}

const RestaurantContext = createContext<RestaurantContextState>(
  {} as RestaurantContextState,
);
RestaurantContext.displayName = 'Restaurant';

export const useRestaurantContext = () => useContext(RestaurantContext);

interface RestaurantContextProviderProps {
  children: ReactNode;
}

export const RestaurantContextProvider = ({
  children,
}: RestaurantContextProviderProps) => {
  const [restaurantDetails, setRestaurantDetails] = useState<RestaurantDetails>(
    {} as RestaurantDetails,
  );

  const value = useMemo<RestaurantContextState>(
    () => ({
      restaurantDetails,
      setRestaurantDetails,
    }),
    [restaurantDetails],
  );

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
