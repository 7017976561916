import { type ApiResponse } from '@shared/types/apiHelpers';
import type { FloorPlanData } from '@shared/types/floorPlans';
import API from '../api/apiClient';

export const getFloorPlanForRestaurantId = async (
  restaurantId: string,
  dynamic: boolean,
): Promise<ApiResponse<FloorPlanData>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/floor-plan${dynamic ? '?dynamic=true' : ''}`,
  );

  return response.json();
};
