import { createContext, type ReactNode, useContext, useMemo } from 'react';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useOverlay } from '@shared/hooks/useOverlay';

interface DrawerContextProviderProps {
  children: ReactNode;
}

interface DrawerContextState {
  closeDrawer: () => void;
  isOpen: boolean;
  openDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextState>(
  {} as DrawerContextState,
);
DrawerContext.displayName = 'Drawer';

export const useDrawerContext = () => useContext(DrawerContext);

export const DrawerContextProvider = ({
  children,
}: DrawerContextProviderProps) => {
  const { isOpen, open: openDrawer, close: closeDrawer } = useIsOpen();

  useOverlay(isOpen);

  const value = useMemo<DrawerContextState>(
    () => ({
      closeDrawer,
      isOpen,
      openDrawer,
    }),
    [isOpen],
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};
