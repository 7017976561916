import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { usePlaidContext } from '../context/PlaidContext';
import { getPayoutLinkToken } from './apiHelpers';

interface FetchLinkTokenProps {
  children: ReactElement | null;
}

export const FetchLinkToken = ({ children }: FetchLinkTokenProps) => {
  const { linkToken, setLinkToken } = usePlaidContext();

  useEffect((): void => {
    void (async () => {
      const token = await getPayoutLinkToken();
      if (typeof token === 'string') {
        setLinkToken(token);
      }
    })();
  }, []);

  return linkToken ? children : null;
};
