/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import type { ReactNode } from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
  Accordion as ReactAccessibleAccordion,
} from 'react-accessible-accordion';
import type { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import { Icon } from '@components/icon/Icon';
import typography from 'shared/styles/typography.scss';
import styles from './Accordion.scss';

interface AccordionProps {
  /**
   * @description Each accordion on the page must have an ID that appears only once on that page. Duplicate IDs will cause the page to fail an accessibility audit.
   */
  accordionId: ID;
  accordionItemHeadingClassName?: string;
  allowMultipleExpanded?: boolean;
  className?: string;
  expandedPanel: any;
  handleOnClickAccordion?: () => void;
  headerAriaLevel?: number;
  /**
   * @description Per [the docs](https://www.npmjs.com/package/react-accessible-accordion#accessibility-best-practice), “Only ever use [phrasing content](https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content) inside of your `AccordionItemHeading` component. If in doubt, use text only.”
   */
  headerComponent: any;
  items: {
    expandedPanelProps?: Record<string, any>;
    headerComponentProps?: Record<string, any>;
    key: string;
  }[];
  peakAccordionItemPanelClassName?: string;
}

export const PeakAccordionButton = ({ children }: { children: ReactNode }) => (
  <AccordionItemButton className={cx(typography.h6, styles.accordionButton)}>
    {children}
    <Icon name="chevron" className={styles.chevron} />
  </AccordionItemButton>
);

interface PeakAccordionItemPanelProps {
  children: ReactNode;
  className?: string;
}

export const PeakAccordionItemPanel = ({
  children,
  className,
}: PeakAccordionItemPanelProps) => (
  <AccordionItemPanel
    className={cx(typography.t1, styles.accordionPanel, className)}
  >
    {children}
  </AccordionItemPanel>
);

export const Accordion = ({
  accordionId,
  accordionItemHeadingClassName,
  allowMultipleExpanded = true,
  className,
  expandedPanel,
  handleOnClickAccordion,
  headerAriaLevel,
  headerComponent,
  items,
  peakAccordionItemPanelClassName,
}: AccordionProps) => {
  const ExpandedPanel = expandedPanel;
  const HeaderComponent = headerComponent;

  return (
    <ReactAccessibleAccordion
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded
      className={cx(styles.accordion, className)}
      onChange={handleOnClickAccordion}
    >
      {items.map((item, i) => (
        <AccordionItem key={item.key} uuid={`${accordionId}-#${i}`}>
          <AccordionItemHeading
            aria-level={headerAriaLevel}
            className={accordionItemHeadingClassName}
          >
            <PeakAccordionButton>
              <HeaderComponent {...item.headerComponentProps} />
            </PeakAccordionButton>
          </AccordionItemHeading>
          <PeakAccordionItemPanel className={peakAccordionItemPanelClassName}>
            <AccordionItemState>
              {(state) => (
                <ExpandedPanel
                  className={styles.expandedPanel}
                  {...item.expandedPanelProps}
                  expanded={state.expanded}
                />
              )}
            </AccordionItemState>
          </PeakAccordionItemPanel>
        </AccordionItem>
      ))}
    </ReactAccessibleAccordion>
  );
};
