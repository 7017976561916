import cx from 'classnames';
import type { ReactNode } from 'react';
import { NavLink, type To } from 'react-router-dom';
import typography from '~styles/typography.scss';
import styles from './SubNavLink.scss';

interface SubNavLinkProps {
  children: ReactNode;
  to: To;
}

export const SubNavLink = ({ children, to }: SubNavLinkProps) => (
  <NavLink
    to={to}
    end
    className={({ isActive }) =>
      cx(typography.t1, styles.button, { [styles.active]: isActive })
    }
  >
    {children}
  </NavLink>
);
