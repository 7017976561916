import {
  format,
  hoursToMinutes,
  hoursToSeconds,
  minutesToSeconds,
  parseISO,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';

const SECONDS_PER_DAY = hoursToSeconds(24);

/**
 * @example
 * ISOTimeOf(13, 29, 7) // "13:29:07"
 * ISOTimeOf(2)         // "02:00:00"
 */
export const ISOTimeOf = (hours: number, minutes?: number, seconds?: number) =>
  [hours, minutes, seconds]
    .map((n) => String(n ?? 0).padStart(2, '0'))
    .join(':');

/**
 * Returns an array of hours, minutes and seconds from an ISO time string
 * @example
 * parseISOTime("13:29:00") // [13, 29, 0]
 * parseISOTime("02:00")    // [2, 0, 0]
 */
export const parseISOTime = (time: string): [number, number, number] => {
  const [hours, minutes, seconds] = time.split(':');
  return [hours, minutes, seconds].map((n) =>
    n == null ? 0 : parseInt(n, 10),
  ) as [number, number, number];
};

export const ISOTimeToMinuteOfDay = (time: string) => {
  const [hours, minutes] = parseISOTime(time);
  return hoursToMinutes(hours) + minutes;
};

export const ISOTimeToSecondOfDay = (time: string) => {
  const [hours, minutes, seconds] = parseISOTime(time);
  return hoursToSeconds(hours) + minutesToSeconds(minutes) + seconds;
};

const secondOfDayToISOTime = (secondOfDay: number) =>
  ISOTimeOf(
    secondsToHours(secondOfDay),
    secondsToMinutes(secondOfDay) % 60,
    secondOfDay % 60,
  );

const minuteOfDayToISOTime = (minuteOfDay: number) =>
  secondOfDayToISOTime(minutesToSeconds(minuteOfDay));

/** Add minutes to a time string with rollover */
export const ISOTimeAddMinutes = (time: string, minutesToAdd: number) =>
  secondOfDayToISOTime(
    (ISOTimeToSecondOfDay(time) + minutesToSeconds(minutesToAdd)) %
      SECONDS_PER_DAY,
  );

/** Adds minutes to a time without going past 24:00:00 */
export const ISOTimeSaturatingAddMinutes = (time: string, minutes: number) =>
  secondOfDayToISOTime(
    Math.min(
      ISOTimeToSecondOfDay(time) + minutesToSeconds(minutes),
      SECONDS_PER_DAY,
    ),
  );

export const ISOTimeFloorToQuarterHour = (time: string) =>
  minuteOfDayToISOTime(Math.floor(ISOTimeToMinuteOfDay(time) / 15) * 15);

/**
 * @example '14:00:00'
 */
export const dateToISOTime = (date: Date): string => format(date, 'HH:mm:ss');

/**
 *
 * @param time e.g., '14:00:00'
 * @example '2:00 PM'
 */
export const ISOTimeTo12HourTime = (time: string): string =>
  format(parseISO(`2000-01-01T${time}`), 'p');
