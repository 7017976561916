import * as Sentry from '@sentry/react';
import { PRODUCTION } from '@shared/environments';

/**
 * Call this function with any caught error that might be worth reporting.
 *
 * You do not need to call this function if the error will be re-thrown or
 * propagated with useError.
 *
 * We use Sentry config to filter out classes of errors that are broadly
 * ignorable.
 */
export const reportAppError = (error: unknown) => {
  if (process.env.ENV !== PRODUCTION) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  Sentry.captureException(error);
};
