import cx from 'classnames';
import buttonStyles from '@components/button/Button.scss';
import { EmailSupport } from '@shared/components/emailSupport/EmailSupport';
import typography from '~styles/typography.scss';
import styles from './ErrorHero.scss';

interface ErrorHeroProps {
  title: string;
  subtitle: string;
}

export const ErrorHero = ({ title, subtitle }: ErrorHeroProps) => (
  <main className={styles.container}>
    <h1 className={typography.d3}>{title}</h1>
    <div className={styles.tableFlipAnimation} aria-hidden="true">
      <p className={styles.tableAscii}>(╯°□°）╯︵ ┻━┻</p>
      <p className={styles.tableAscii}>┬─┬ノ( º _ ºノ)</p>
    </div>
    <p className={typography.c2}>{subtitle}</p>
    <div className={styles.actionContainer}>
      <EmailSupport
        className={cx(
          buttonStyles.btn,
          buttonStyles.btnTertiary,
          typography.h6,
          styles.button,
        )}
        label="Contact us for help"
      />
    </div>
  </main>
);
