import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './Button.scss';
import deprecatedStyles from './DeprecatedButtonStyles.scss';

export enum ButtonVariants {
  Default = 'default',
  DefaultWhite = 'defaultWhite',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'outline',
  Error = 'error',
}

type ButtonType = 'reset' | 'submit' | 'button' | undefined;

interface WithTypeFormProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type: 'submit' | 'reset';
}

interface WithTypeButtonClickProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: 'button';
}

interface WithNoTypeProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

type ButtonProps = {
  className?: string;
  dataTestId?: string;
  form?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  name?: string;
  useDeprecatedBreakpoint?: boolean;
  variant: ButtonVariants;
} & (WithTypeFormProps | WithTypeButtonClickProps | WithNoTypeProps);

export const Button = ({
  className,
  dataTestId,
  isDisabled = false,
  isLoading = false,
  label,
  name,
  onClick,
  useDeprecatedBreakpoint = false,
  variant,
  ...props
}: ButtonProps) => {
  const buttonClassNames = cx({
    [styles.btnDefault]: variant === ButtonVariants.Default,
    [styles.btnDefaultWhite]: variant === ButtonVariants.DefaultWhite,
    [styles.btnTertiary]: variant === ButtonVariants.Tertiary,
    [styles.btnPrimary]: variant === ButtonVariants.Primary,
    [styles.btnSecondary]: variant === ButtonVariants.Secondary,
    [styles.btnError]: variant === ButtonVariants.Error,
    [styles.btn]: true,
    [deprecatedStyles.deprecatedBtn]: useDeprecatedBreakpoint,
    [typography.h6]: true,
  });

  let buttonType: ButtonType = 'button';
  if ('type' in props) {
    buttonType = props.type;
  }

  return (
    <button
      className={cx(buttonClassNames, className)}
      data-testid={dataTestId}
      disabled={isDisabled || isLoading || false}
      name={name}
      onClick={onClick}
      type={buttonType}
    >
      {label}
    </button>
  );
};
