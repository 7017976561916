// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountsSidebar__container___B5FyE{display:flex;flex-direction:column;padding:40px;width:14rem}.AccountsSidebar__container___B5FyE h1{margin-top:0}@media only screen and (min-width: 1024px){.AccountsSidebar__container___B5FyE{position:absolute}}.AccountsSidebar__signOut____3PN5{color:var(--white);text-align:left;padding:0;border:none;cursor:pointer;background-color:rgba(0,0,0,0)}.AccountsSidebar__sideBarLink___li5KV{color:var(--white);margin-bottom:16px}.AccountsSidebar__activeLink___WZmpt{color:var(--gold)}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/AccountsSidebar.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,WAAA,CAEA,uCACE,YAAA,CCuBF,2CD9BF,oCAWI,iBAAA,CAAA,CAIJ,kCACE,kBAAA,CACA,eAAA,CACA,SAAA,CACA,WAAA,CACA,cAAA,CACA,8BAAA,CAGF,sCACE,kBAAA,CACA,kBAAA,CAGF,qCACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AccountsSidebar__container___B5FyE",
	"signOut": "AccountsSidebar__signOut____3PN5",
	"sideBarLink": "AccountsSidebar__sideBarLink___li5KV",
	"activeLink": "AccountsSidebar__activeLink___WZmpt"
};
export default ___CSS_LOADER_EXPORT___;
