import { Modal as MUIModal } from '@mui/base/Modal';
import { createRef, type ReactNode } from 'react';
import typography from '~styles/typography.scss';
import { Icon } from '../icon/Icon';
import { Backdrop } from './Backdrop';
import styles from './Modal.scss';

interface ModalBasicProps {
  children?: ReactNode;
  disablePortal?: boolean;
  isOpen: boolean;
  onClose: () => void;
  subtitle?: string;
}
interface ModalTitleProps {
  ariaLabel?: never;
  title: string;
}
interface ModalAriaLabelProps {
  ariaLabel: string;
  title?: never;
}

export type ModalProps = ModalBasicProps &
  (ModalTitleProps | ModalAriaLabelProps);

export const Modal = ({
  ariaLabel,
  children,
  disablePortal,
  isOpen,
  onClose,
  subtitle,
  title,
}: ModalProps) => {
  const ariaLabelProp = title
    ? { 'aria-labelledby': 'dialogTitle' }
    : { 'aria-label': ariaLabel };

  const modalContent = (
    <div {...ariaLabelProp} className={styles.modalContent} role="dialog">
      <button
        aria-label="Close modal"
        className={styles.closeButton}
        onClick={onClose}
        type="button"
      >
        <Icon className={styles.closeIcon} name="close" />
      </button>
      {title && (
        <h1 className={typography.d3} id="dialogTitle">
          {title}
        </h1>
      )}
      {subtitle && <p className={typography.c2}>{subtitle}</p>}
      {children}
    </div>
  );

  if (!isOpen) return null;

  if (disablePortal) {
    const containerRef = createRef<HTMLDivElement>();
    return (
      <div data-testid="click-on-outside-shield" ref={containerRef}>
        <MUIModal
          container={() => containerRef.current}
          disablePortal
          className={styles.modal}
          onClose={onClose}
          open={isOpen}
          role="main"
          slotProps={{
            backdrop: { className: styles.backdrop },
            root: { onClick: (event) => event.stopPropagation() },
          }}
          slots={{ backdrop: Backdrop }}
        >
          {modalContent}
        </MUIModal>
      </div>
    );
  }

  return (
    <MUIModal
      className={styles.modal}
      onClose={onClose}
      open={isOpen}
      role="main"
      slotProps={{
        backdrop: { className: styles.backdrop },
        root: { onClick: (event) => event.stopPropagation() },
      }}
      slots={{ backdrop: Backdrop }}
    >
      {modalContent}
    </MUIModal>
  );
};
