import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import typography from '~styles/typography.scss';
import { RESTAURANT_DEMO_PATH } from '../paths';
import { InfoTabContentWide } from './InfoTabContentWide';
import styles from './InfoTabsWide.scss';
import { Tab } from './utils';

export const InfoTabsWide = () => {
  const [activeTab, setActiveTab] = useState<Tab>(Tab.ForRestaurants);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const handleOnTabClick = (tab: Tab) => () => {
    setActiveSlideIndex(0);
    setActiveTab(tab);
  };

  return (
    <section className={styles.section}>
      <h2 className={typography.d3}>One Platform Built for Two Worlds</h2>
      <div className={styles.buttonContainer}>
        <Button
          label="For Restaurants"
          variant={
            activeTab === Tab.ForRestaurants
              ? ButtonVariants.Primary
              : ButtonVariants.Tertiary
          }
          onClick={handleOnTabClick(Tab.ForRestaurants)}
        />
        <Button
          label="For Diners"
          variant={
            activeTab === Tab.ForDiners
              ? ButtonVariants.Primary
              : ButtonVariants.Tertiary
          }
          onClick={handleOnTabClick(Tab.ForDiners)}
        />
      </div>
      <InfoTabContentWide
        activeTab={activeTab}
        activeSlideIndex={activeSlideIndex}
        setActiveSlideIndex={setActiveSlideIndex}
      />
      <LinkStyledAsButton
        label="Use Peak for Your Restaurant"
        to={RESTAURANT_DEMO_PATH}
        variant={ButtonVariants.Tertiary}
      />
    </section>
  );
};
