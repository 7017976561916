import cx from 'classnames';
import type { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Error } from '@components/error/Error';
import { isCancellationPolicyApplicable } from '@shared/reservations/reservationUtil';
import { CONFLICT } from '@shared/statusCodes';
import type { ApiResponse } from '@shared/types/apiHelpers';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { CANCELLATION_POLICY_HAS_CHANGED_ERROR_MESSAGE } from 'webReservations/errors/messages';
import { useError } from 'webReservations/hooks/useError';
import { getRestaurantDetailsById } from 'webReservations/restaurant/apiHelpers';
import typography from '~styles/typography.scss';
import { useOfferCheckoutContext } from '../context/OfferCheckoutContext';
import { useRestaurantContext } from '../context/RestaurantContext';
import { OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH } from '../paths';
import type { CreatedOffer } from './apiHelpers';
import { createOffer } from './apiHelpers';
import styles from './CheckoutForm.scss';
import { sendGa4SendOfferEvent } from './gaHelpers';
import { PaymentMethods } from './PaymentMethods';
import { usePaymentMethods } from './usePaymentMethods';

interface OfferCheckoutForm {
  disabled: boolean;
  offerAmount: number;
}

export const OfferCheckoutForm = ({
  disabled,
  offerAmount,
}: OfferCheckoutForm) => {
  const navigate = useNavigate();
  const setError = useError();
  const { restaurantDetails, setRestaurantDetails } = useRestaurantContext();
  const {
    offerCheckout: { date, guestCount, listingId, publicName, time },
  } = useOfferCheckoutContext();

  const {
    processPaymentMethod,
    selectedPaymentMethodId,
    setupFutureCardUsage,
    handleOnError,
    isLoading,
    paymentMethods,
    handleOnChangePaymentMethod,
    handleOnChangeName,
    handleOnChangeCardElement,
    handleOnChangeSaveCard,
    handleOnDeletePaymentMethod,
    checkoutResponseError,
    isPayButtonDisabled,
  } = usePaymentMethods();

  const handleOnClickSendOffer = async (event: FormEvent) => {
    event.preventDefault();

    const { cancellationPolicy } = restaurantDetails;
    const hasApplicableCancellationPolicy =
      !!cancellationPolicy &&
      isCancellationPolicyApplicable(offerAmount, cancellationPolicy.threshold);

    const newPaymentMethod = await processPaymentMethod(event);
    if (!selectedPaymentMethodId && !newPaymentMethod) return;

    const createOfferResponse: ApiResponse<CreatedOffer> = await createOffer({
      date,
      expectedCancellationPolicyId: hasApplicableCancellationPolicy
        ? cancellationPolicy.id
        : null,
      guestCount,
      listingId,
      offerAmount,
      paymentMethodId:
        selectedPaymentMethodId || newPaymentMethod?.paymentMethod.id || '',
      setupFutureCardUsage,
      time,
    });

    if (isErrorResponse(createOfferResponse)) {
      if (createOfferResponse.statusCode === CONFLICT) {
        const restaurantDetailsResponse = await getRestaurantDetailsById(
          restaurantDetails.id,
        );
        if (isErrorResponse(restaurantDetailsResponse)) {
          setError(restaurantDetailsResponse.message);
          return;
        }
        setRestaurantDetails(restaurantDetailsResponse);
        handleOnError(CANCELLATION_POLICY_HAS_CHANGED_ERROR_MESSAGE);
        return;
      }
      handleOnError(createOfferResponse.message);
    } else {
      navigate(OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH, {
        replace: true,
        state: {
          date,
          fee: createOfferResponse.fee,
          guestCount,
          offerAmount: createOfferResponse.price,
          publicName,
          tax: createOfferResponse.tax,
          time,
          total: createOfferResponse.total,
        },
      });

      sendGa4SendOfferEvent({
        date,
        offerAmount: createOfferResponse.price,
        restaurantName: restaurantDetails.name,
        publicName,
        time,
      });
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <form id="payment-form" onSubmit={handleOnClickSendOffer}>
      <PaymentMethods
        paymentMethods={paymentMethods}
        selectedPaymentMethodId={selectedPaymentMethodId}
        setupFutureCardUsage={setupFutureCardUsage}
        handleOnChangePaymentMethod={handleOnChangePaymentMethod}
        handleOnChangeName={handleOnChangeName}
        handleOnChangeCardElement={handleOnChangeCardElement}
        handleOnChangeSaveCard={handleOnChangeSaveCard}
        handleOnDeletePaymentMethod={handleOnDeletePaymentMethod}
      />
      <p className={cx(typography.t2, styles.offerNotice)}>
        Your credit card will not be charged unless the offer is accepted
      </p>
      <Error message={checkoutResponseError} />
      <Button
        className={styles.bookAndPay}
        isDisabled={isPayButtonDisabled || disabled}
        label="Send Offer"
        type="submit"
        useDeprecatedBreakpoint
        variant={ButtonVariants.Primary}
      />
      <Button
        className={styles.cancel}
        label="Cancel"
        onClick={() => navigate(-1)}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Tertiary}
      />
    </form>
  );
};
