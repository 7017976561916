import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { getRestaurants, type Restaurant } from '../restaurant/apiHelpers';

export const restaurantsLoader = async (): Promise<Restaurant[]> => {
  const restaurantResponse = await getRestaurants();

  if (isErrorResponse(restaurantResponse)) {
    throw createResponseError(restaurantResponse);
  }

  return restaurantResponse;
};
