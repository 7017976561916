import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import {
  ACCEPT_TOP_OFFER,
  AcceptTopOfferModal,
} from '../offers/AcceptTopOfferModal';
import { sendGa4OfferAcceptStartEvent } from '../offers/gaHelpers';
import { getTopOffer, type TopOffer } from '../reservationDetails/apiHelpers';
import type { Reservation } from './apiHelpers';
import styles from './ReservationCardActions.scss';
import {
  UPDATE_LIST_PRICE,
  UpdateListPriceModal,
} from './UpdateListPriceModal';

export interface ReservationCardActionsProps {
  buyNowPrice: number | null;
  fetchConfirmedReservations: () => Promise<void>;
  reservation: Reservation;
}

export const ReservationCardActions = ({
  buyNowPrice,
  fetchConfirmedReservations,
  reservation,
}: ReservationCardActionsProps) => {
  const [topOffer, setTopOffer] = useState<TopOffer>();
  const [openModal, setOpenModal] = useState<
    'acceptTopOffer' | 'updateListPrice' | null
  >(null);

  useAbortEffect(async (signal) => {
    const response = await getTopOffer(reservation.id, signal);

    if (isSuccessResponse(response)) {
      setTopOffer(response);
    }
  }, []);

  const hasActions = buyNowPrice || !!topOffer?.id;
  const actionsStyle = hasActions
    ? cx(styles.actions, styles.hasActions)
    : styles.actions;

  return (
    <div className={actionsStyle} data-testid="card-actions">
      {buyNowPrice && (
        <Button
          className={cx(styles.listPriceButton, typography.h7)}
          label={`List Price ${centsToWholeDollar(buyNowPrice)}`}
          onClick={() => setOpenModal(UPDATE_LIST_PRICE)}
          variant={ButtonVariants.Default}
        />
      )}
      {!!topOffer?.id && (
        <>
          <Button
            className={cx(styles.sellNowButton, typography.h8)}
            label={`Sell now for ${centsToWholeDollar(topOffer.price)}`}
            onClick={() => {
              sendGa4OfferAcceptStartEvent({
                date: reservation.date,
                offerAmount: topOffer.price,
                publicName: reservation.listing.publicName,
                restaurantName: reservation.restaurant.name,
                time: reservation.time,
              });
              setOpenModal(ACCEPT_TOP_OFFER);
            }}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
          <AcceptTopOfferModal
            closeModal={() => setOpenModal(null)}
            isOpen={openModal === ACCEPT_TOP_OFFER}
            offer={topOffer}
            reservation={reservation}
          />
        </>
      )}
      {buyNowPrice && (
        <UpdateListPriceModal
          closeModal={() => setOpenModal(null)}
          isOpen={openModal === UPDATE_LIST_PRICE}
          listPrice={buyNowPrice}
          onSubmit={fetchConfirmedReservations}
          reservationId={reservation.id}
        />
      )}
    </div>
  );
};
