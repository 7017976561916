import cx from 'classnames';
import type { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { IPAD768 } from '@shared/styles/breakpoints';
import typography from '~styles/typography.scss';
import { ImageLoader } from '../components/ImageLoader';
import type { Restaurant } from './apiHelpers';
import { ReservationAttributesGrid } from './ReservationAttributesGrid';
import { ReservationAttributesInline } from './ReservationAttributesInline';
import styles from './ReservationCard.scss';
import { ReservationStatus } from './ReservationStatus';
import type { ReservationStatusType } from './statusHelpers';

export interface ReservationCardProps {
  children?: ReactNode;
  date: string;
  guestCount: number;
  linkTo: string;
  restaurant: Restaurant;
  status?: ReservationStatusType;
  listingPublicName: string;
  time: string;
}

export const ReservationCard = ({
  children,
  date,
  guestCount,
  linkTo,
  restaurant,
  status,
  listingPublicName,
  time,
}: ReservationCardProps) => {
  const isTabletOrMobile = useMediaQuery({ minWidth: IPAD768 });

  return (
    <li className={styles.container}>
      <Link className={styles.linkContainer} to={linkTo}>
        <div className={styles.imageContainer}>
          <ImageLoader
            src={`${process.env.RESTAURANT_IMAGE_BASE_URL}/${restaurant.id}/main.jpg`}
          />
        </div>
        <div className={styles.content}>
          <h2 className={cx(typography.d4, styles.restaurantName)}>
            {restaurant.name}
          </h2>
          {status && <ReservationStatus status={status} />}
          {isTabletOrMobile ? (
            <ReservationAttributesGrid
              date={date}
              guestCount={guestCount}
              publicName={listingPublicName}
              time={time}
            />
          ) : (
            <ReservationAttributesInline
              date={date}
              guestCount={guestCount}
              listingPublicName={listingPublicName}
              time={time}
            />
          )}
        </div>
      </Link>
      {children}
    </li>
  );
};
