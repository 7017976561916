import ReactGA from 'react-ga4';

interface FAQOpenClickEvent {
  sectionName: string;
  question: string;
  answer: string;
}

export const sendGa4FAQOpenClickEvent = ({
  sectionName,
  question,
  answer,
}: FAQOpenClickEvent) => {
  ReactGA.event('faq_open_click', {
    section_name: sectionName,
    question,
    answer,
  });
};
