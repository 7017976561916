import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { SignOutModal } from 'webReservations/auth/SignOutModal';
import {
  ACCOUNT_BALANCE_PATH,
  ACCOUNT_NOTIFICATIONS_PATH,
  ACCOUNT_PAYMENT_METHODS_PATH,
} from 'webReservations/paths';
import typography from '~styles/typography.scss';
import styles from './AccountsSidebar.scss';

export const AccountsSidebar = () => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();

  const linkClassName = ({ isActive }: { isActive: boolean }): string =>
    cx({
      [typography.h6]: true,
      [styles.sideBarLink]: true,
      [styles.activeLink]: isActive,
    });

  return (
    <>
      <div className={styles.container}>
        <h1 className={typography.h2}>Account</h1>
        <NavLink className={linkClassName} to={ACCOUNT_NOTIFICATIONS_PATH}>
          Notifications
        </NavLink>
        <NavLink className={linkClassName} to={ACCOUNT_BALANCE_PATH}>
          Balance
        </NavLink>
        <NavLink className={linkClassName} to={ACCOUNT_PAYMENT_METHODS_PATH}>
          Payment Methods
        </NavLink>
        <button
          className={cx(typography.h6, styles.signOut)}
          onClick={openModal}
          type="button"
        >
          Sign Out
        </button>
      </div>
      <SignOutModal closeModal={closeModal} isOpen={isModalOpen} />
    </>
  );
};
