import { MenuItem } from '@mui/base';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu as MuiMenu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import cx from 'classnames';
import { type MouseEvent, useRef } from 'react';
import { type MenuOptionProps } from '@components/menu/types';
import { Icon } from '../icon/Icon';
import styles from './Kebab.scss';

export interface KebabOption extends MenuOptionProps {
  variant?: KebabOptionVariants;
}

export interface KebabProps {
  ariaLabelPrefix?: string;
  className?: string;
  options?: KebabOption[];
}

export enum KebabOptionVariants {
  Pink = 'pink',
}

const sanitizeOption = (option: KebabOption): MenuOptionProps => {
  const { variant, optionClassName, type, ...props } = option;

  const optionCombinedClassName = cx(
    styles.optionButton,
    {
      [styles.itemPink]: variant === KebabOptionVariants.Pink,
    },
    optionClassName,
  );

  return {
    ...props,
    optionClassName: optionCombinedClassName,
    type: type || 'button',
  };
};

export const Kebab = ({
  ariaLabelPrefix,
  className,
  options = [],
}: KebabProps) => {
  const menuRef = useRef<HTMLElement>(null);
  const refCurrent = () => menuRef.current!;

  const menuItemOptions = options.map(sanitizeOption);

  return (
    // The <div> element has a child <button> element that allows keyboard interaction
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx(styles.menuWrapper, className)}
      onClick={(e: MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={(e: MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Dropdown>
        <MenuButton
          aria-label={[ariaLabelPrefix, 'options'].join(' ')}
          className={styles.menuButton}
          ref={menuRef}
        >
          <Icon className={styles.icon} name="options" />
        </MenuButton>
        <MuiMenu
          anchor={refCurrent}
          className={styles.menu}
          placement="left-start"
        >
          {menuItemOptions.map(
            ({ label, optionClassName, ...buttonProps }: MenuOptionProps) => (
              <MenuItem
                className={optionClassName}
                disabled={buttonProps.disabled}
                key={label}
              >
                <button {...buttonProps}>{label}</button>
              </MenuItem>
            ),
          )}
        </MuiMenu>
      </Dropdown>
    </div>
  );
};
