// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailVerification__container___RFyKO{align-items:center;color:var(--white);display:flex;flex-direction:column;justify-content:center;margin:0 auto;max-width:28rem;padding:4rem 2rem;text-align:center;width:100%}.EmailVerification__logo___o8cOX{height:128px;margin-bottom:4rem;stroke:var(--accent300)}.EmailVerification__resendButton___saLYL{width:100%}.EmailVerification__statusMessage___pgdK5{margin-top:16px;text-align:center;visibility:hidden}.EmailVerification__showStatus___M9r1L{visibility:visible}", "",{"version":3,"sources":["webpack://./src/webReservations/email/EmailVerification.scss"],"names":[],"mappings":"AAAA,sCACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CAGF,iCACE,YAAA,CACA,kBAAA,CACA,uBAAA,CAGF,yCACE,UAAA,CAGF,0CACE,eAAA,CACA,iBAAA,CACA,iBAAA,CAGF,uCACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmailVerification__container___RFyKO",
	"logo": "EmailVerification__logo___o8cOX",
	"resendButton": "EmailVerification__resendButton___saLYL",
	"statusMessage": "EmailVerification__statusMessage___pgdK5",
	"showStatus": "EmailVerification__showStatus___M9r1L"
};
export default ___CSS_LOADER_EXPORT___;
