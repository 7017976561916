const FIRST_CHAR = 0;

export const getInitials = (firstName?: string, lastName?: string): string =>
  [firstName, lastName]
    .filter((defined) => defined)
    .map((name) => name?.charAt(FIRST_CHAR))
    .join('')
    .toUpperCase();

export const getFullName = (firstName?: string, lastName?: string): string =>
  [firstName?.trim(), lastName?.trim()].filter((defined) => defined).join(' ');

export const getAnonymousName = (
  firstName?: string,
  lastName?: string,
): string => {
  const name = getFullName(firstName, lastName) || 'Guest';
  return name;
};
