// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReceiptDetails__container___Mt7yu{border-top:1px solid var(--hairline);display:flex;flex-direction:column;gap:2rem;margin-top:2.5rem;padding-top:2.5rem}.ReceiptDetails__container___Mt7yu h2{margin:0}.ReceiptDetails__container___Mt7yu dl{display:grid;gap:2rem 1rem;grid-template-columns:max-content auto}.ReceiptDetails__container___Mt7yu dl dt{color:var(--white70);font-size:inherit;white-space:nowrap}.ReceiptDetails__container___Mt7yu dl dd{color:#fff;font-size:inherit;margin:0;text-align:right}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ReceiptDetails.scss"],"names":[],"mappings":"AAAA,mCACE,oCAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,iBAAA,CACA,kBAAA,CAEA,sCACE,QAAA,CAGF,sCACE,YAAA,CACA,aAAA,CACA,sCAAA,CAEA,yCACE,oBAAA,CACA,iBAAA,CACA,kBAAA,CAGF,yCACE,UAAA,CACA,iBAAA,CACA,QAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReceiptDetails__container___Mt7yu"
};
export default ___CSS_LOADER_EXPORT___;
