export type Environment =
  | 'development'
  | 'ci'
  | 'staging'
  | 'production'
  | 'demo';
const PRODUCTION: Environment = 'production';
const STAGING: Environment = 'staging';
const DEVELOPMENT: Environment = 'development';
const DEMO: Environment = 'demo';

export { PRODUCTION, STAGING, DEVELOPMENT, DEMO };
