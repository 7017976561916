import cx from 'classnames';
import { type AnyIcon, Icon } from '@components/icon/Icon';
import { Kebab, KebabOptionVariants } from '@components/kebab/Kebab';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '@shared/styles/typography.scss';
import { type PaymentMethod } from 'webReservations/checkout/apiHelpers';
import { DeletePaymentMethodModal } from 'webReservations/checkout/DeletePaymentMethodModal';
import styles from './SavedSettingsPaymentMethod.scss';

const CC_EXP_MONTH_TARGET_LENGTH = 2;
const CC_EXP_MONTH_PAD_STRING = '0';
const CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END = -2;

const cardBrandToIcon = (brand: string): AnyIcon => {
  // Card brands are PROBABLY lowercase, but the docs at https://docs.stripe.com/api/cards/object#card_object-brand don't agree with Stripe's code comments in api/node_modules/stripe/types/PaymentMethods.d.ts, so let's just make sure before comparing.
  switch (brand.toLowerCase()) {
    case 'amex':
      return 'amex';
    case 'mastercard':
      return 'mastercard';
    case 'visa':
      return 'visa';
    default:
      return 'creditCard';
  }
};

interface SavedSettingsPaymentMethodProps {
  handleOnDeletePaymentMethod: (id: string) => void;
  paymentMethod: PaymentMethod;
}

export const SavedSettingsPaymentMethod = ({
  handleOnDeletePaymentMethod,
  paymentMethod: { brand, expirationMonth, expirationYear, id, last4 },
}: SavedSettingsPaymentMethodProps) => {
  const {
    isOpen: isDeletePaymentMethodModalOpen,
    open: openDeletePaymentMethodModal,
    close: closeDeletePaymentMethodModal,
  } = useIsOpen();

  const handleConfirmDeletePaymentMethod = () => {
    handleOnDeletePaymentMethod(id);
    closeDeletePaymentMethodModal();
  };

  return (
    <>
      <li className={styles.method}>
        <span className={styles.brand}>
          <Icon ariaLabel={brand} name={cardBrandToIcon(brand)} role="img" />
        </span>
        <span className={cx(typography.c3, styles.ccn)}>
          <span aria-hidden="true">•••• </span>
          {last4}
        </span>
        <span className={cx(typography.c3, styles.expiration)}>
          Expires{' '}
          {`${expirationMonth
            .toString()
            .padStart(
              CC_EXP_MONTH_TARGET_LENGTH,
              CC_EXP_MONTH_PAD_STRING,
            )}/${expirationYear
            .toString()
            .slice(CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END)}`}
        </span>
        <Kebab
          className={styles.kebab}
          options={[
            {
              label: 'Delete Payment Method',
              onClick: openDeletePaymentMethodModal,
              variant: KebabOptionVariants.Pink,
            },
          ]}
        />
      </li>
      <DeletePaymentMethodModal
        isOpen={isDeletePaymentMethodModalOpen}
        onClose={closeDeletePaymentMethodModal}
        onConfirm={handleConfirmDeletePaymentMethod}
      />
    </>
  );
};
