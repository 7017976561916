import cx from 'classnames';
import type { ChangeEvent, KeyboardEvent } from 'react';
import typography from '~styles/typography.scss';
import styles from './CurrencyInput.scss';

const NON_NUMBER_CHARS = /[^0-9]/;
const LEADING_ZEROS = /^0+/;

interface CurrencyInputProps {
  className?: string;
  id: string;
  label: string;
  maxValue?: number;
  name: string;
  onChange: (value: string, name: string) => void;
  placeholder?: string;
  value?: string;
}

export const CurrencyInput = ({
  className,
  id,
  label,
  maxValue,
  name,
  onChange,
  placeholder,
  value,
}: CurrencyInputProps) => {
  const displayValue = value ? `$${value}` : '';

  return (
    <label className={cx(typography.c3, className)} htmlFor={id}>
      <span>{label}</span>
      <input
        className={styles.input}
        id={id}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const cleanValue = e.target.value
            .replace(NON_NUMBER_CHARS, '')
            .replace(LEADING_ZEROS, '');
          if (maxValue && Number(cleanValue) > maxValue) return;
          onChange(cleanValue, name);
        }}
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
          // `keyCode` and `which` are deprecated, but we'll retain support for compatibility with older browsers
          const keyCode = e.keyCode || e.which;
          const keyValue = e.key || String.fromCharCode(keyCode);
          if (NON_NUMBER_CHARS.test(keyValue)) e.preventDefault();
        }}
        placeholder={placeholder}
        type="text"
        value={displayValue}
      />
    </label>
  );
};
