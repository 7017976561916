interface ExternalRefundLinkProps {
  className: string;
  label?: string;
}

export const ExternalRefundLink = ({
  className,
  label = 'Terms of Service',
}: ExternalRefundLinkProps) => (
  <a
    className={className}
    href={`${process.env.WEB_CUSTOMER_HOST}/terms-of-service#cancellations-and-refunds`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {label}
  </a>
);
