// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Divider__divider___mV6j9{background-color:var(--darkGrey200);height:1px;opacity:.2;width:100%}", "",{"version":3,"sources":["webpack://./src/shared/components/divider/Divider.scss"],"names":[],"mappings":"AAAA,0BACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "Divider__divider___mV6j9"
};
export default ___CSS_LOADER_EXPORT___;
