import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { WideView } from '@components/wideView/WideView';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import { isoToShortDate } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { ImageLoader } from '../components/ImageLoader';
import { useTimer } from '../hooks/useTimer';
import type { TopOffer } from '../reservationDetails/apiHelpers';
import type { Reservation } from '../reservations/apiHelpers';
import { ReservationCard } from '../reservations/ReservationCard';
import { formatTimeRemaining } from '../utils/formatTimeRemaining';
import { AcceptTopOfferModal } from './AcceptTopOfferModal';
import { sendGa4OfferAcceptStartEvent } from './gaHelpers';
import styles from './OfferCard.scss';

const nbsp = '\u00A0';

export interface OfferInCardProps {
  reservation: Reservation;
  topOffer: TopOffer;
}

export const OfferInCard = ({ reservation, topOffer }: OfferInCardProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();

  const timeRemaining = useTimer(topOffer);

  const buttonLabel = `Sell${nbsp}now${nbsp}for${nbsp}${centsToWholeDollar(
    topOffer.price,
  )}`;

  const linkTo = `/reservations/${reservation.id}`;

  const handleAcceptOfferClick = () => {
    sendGa4OfferAcceptStartEvent({
      date: reservation.date,
      offerAmount: topOffer.price,
      publicName: reservation.listing.publicName,
      restaurantName: reservation.restaurant.name,
      time: reservation.time,
    });
    openModal();
  };

  return (
    <>
      <WideView
        narrowElement={
          <li className={styles.offersInMobileContainer}>
            <Link className={styles.linkContainer} to={linkTo}>
              <div className={styles.topRow}>
                <div className={styles.imageBox}>
                  <ImageLoader
                    className={styles.image}
                    src={`${process.env.RESTAURANT_IMAGE_BASE_URL}/${reservation.restaurant.id}/main.jpg`}
                  />
                </div>
                <div className={styles.reservationAttributes}>
                  <div className={cx(typography.d5, styles.name)}>
                    {reservation.restaurant.name}
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="calendar" />
                    {`${isoToShortDate(reservation.date)} - ${ISOTimeTo12HourTime(
                      reservation.time,
                    )}`}
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="table" />
                    {reservation.listing.publicName}
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="couple" />
                    {reservation.guestCount}
                  </div>
                </div>
              </div>
              <div className={styles.offerBox}>
                <div className={cx(typography.t2, styles.expiration)}>
                  <Icon className={styles.icon} name="timer" /> Offer expires
                  {timeRemaining && (
                    <span>{formatTimeRemaining(timeRemaining)}</span>
                  )}
                </div>
                <Button
                  className={styles.mobileButton}
                  label={buttonLabel}
                  onClick={handleAcceptOfferClick}
                  variant={ButtonVariants.Primary}
                />
              </div>
            </Link>
          </li>
        }
        wideElement={
          <ReservationCard
            date={reservation.date}
            guestCount={reservation.guestCount}
            linkTo={linkTo}
            listingPublicName={reservation.listing.publicName}
            restaurant={reservation.restaurant}
            time={reservation.time}
          >
            <div className={styles.actions}>
              <Button
                className={styles.acceptTopOfferCta}
                label={buttonLabel}
                onClick={handleAcceptOfferClick}
                variant={ButtonVariants.Primary}
              />
              <div className={cx(typography.t1, styles.expiration)}>
                <Icon className={styles.icon} name="timer" />
                {timeRemaining && formatTimeRemaining(timeRemaining)}
              </div>
            </div>
          </ReservationCard>
        }
      />
      <AcceptTopOfferModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        offer={topOffer}
        reservation={reservation}
      />
    </>
  );
};
