import ReactGA from 'react-ga4';
import { centsToDecimal } from '@utils/currency';

interface OfferUpdateEventParameters {
  date: string;
  offerAmount: number;
  restaurantName: string;
  publicName: string;
  time: string;
}

export const sendGa4OfferUpdateStartEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_update_start', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferUpdateConfirmEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_update_confirm', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferUpdateAbortEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_update_abort', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferWithdrawStartEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_withdraw_start', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferWithdrawConfirmEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_withdraw_confirm', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferWithdrawAbortEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_withdraw_abort', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferAcceptAbortEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_accept_abort', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferAcceptStartEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_accept_start', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OfferAcceptConfirmEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_accept_confirm', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};

export const sendGa4OffersInViewEvent = () => {
  ReactGA.event('offer_in_view');
};

export const sendGa4OffersOutViewEvent = () => {
  ReactGA.event('offer_out_view');
};

export const sendGa4OfferDetailViewEvent = ({
  date,
  offerAmount,
  restaurantName,
  publicName,
  time,
}: OfferUpdateEventParameters): void => {
  ReactGA.event('offer_detail_view', {
    offer_amount: centsToDecimal(offerAmount),
    reservation_date: date,
    reservation_time: time,
    restaurant_name: restaurantName,
    public_name: publicName,
  });
};
