import type { Context } from 'react';
import { useContext } from 'react';

/**
 * Like useContext, but throws if the context is null.
 *
 * This is useful when you have a context with a default value of null and a
 * Provider that always provides a non-null value.
 */
export const useDefinedContext = <T>(context: Context<T>): NonNullable<T> => {
  const value = useContext(context);
  if (value == null) {
    throw new Error(
      `${context.displayName || 'Context'} is null. You might not be within the context provider.`,
    );
  }
  return value;
};
