import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { CurrencyInput } from '@components/currencyInput/CurrencyInput';
import { Modal } from '@components/modal/Modal';
import { MAXIMUM_LIST_PRICE_IN_DOLLARS } from '@shared/reservations/constants';
import { centsToWholeDollar, wholeDollarsToCents } from '@utils/currency';
import typography from '~styles/typography.scss';
import { RETRYABLE_ERROR_MESSAGE } from '../errors/messages';
import { clearListPrice, updateListPrice } from './apiHelpers';
import styles from './UpdateListPriceModal.scss';

export const UPDATE_LIST_PRICE = 'updateListPrice';

export interface UpdateListPriceModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listPrice: number;
  onSubmit: (reservationId: string) => void;
  reservationId: string;
}

export const UpdateListPriceModal = ({
  closeModal,
  isOpen,
  listPrice,
  onSubmit,
  reservationId,
}: UpdateListPriceModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newListPrice, setNewListPrice] = useState('');

  const handleUpdateListPrice = (updateType?: 'remove'): void => {
    void (async () => {
      setIsLoading(true);

      const success =
        updateType === 'remove'
          ? await clearListPrice(reservationId)
          : await updateListPrice(
              reservationId,
              wholeDollarsToCents(Number(newListPrice)),
            );

      if (success) {
        onSubmit(reservationId);
        closeModal();
      } else {
        setErrorMessage(RETRYABLE_ERROR_MESSAGE);
        setIsLoading(false);
      }
    })();
  };

  return (
    <Modal
      ariaLabel="Update Listing Price"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.hgroup}>
          <h1 className={cx(typography.d3)}>Update Listing&nbsp;Price</h1>
          <Button
            className={cx(typography.c2, styles.linkButton)}
            isLoading={isLoading}
            label="Unlist"
            onClick={() => handleUpdateListPrice('remove')}
            variant={ButtonVariants.Default}
          />
        </div>
        <div className={styles.listPriceForm}>
          <div className={styles.currentListPrice}>
            <div>Current List Price</div>
            <div className={typography.h4}>{centsToWholeDollar(listPrice)}</div>
          </div>
          <CurrencyInput
            className={styles.listPriceInput}
            id="newListPriceInput"
            label="List price"
            maxValue={MAXIMUM_LIST_PRICE_IN_DOLLARS}
            name="newListPriceInput"
            onChange={setNewListPrice}
            value={newListPrice}
          />
        </div>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        <div className={styles.actions}>
          <Button
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            isDisabled={!newListPrice}
            isLoading={isLoading}
            label="Update"
            onClick={() => handleUpdateListPrice(undefined)}
            variant={ButtonVariants.Primary}
          />
        </div>
      </div>
    </Modal>
  );
};
