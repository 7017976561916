import { getS3AssetPath } from '@utils/getS3AssetPath';

export const enum Tab {
  ForRestaurants = 'ForRestaurants',
  ForDiners = 'ForDiners',
}

interface InfoContent {
  title: string;
  description: string;
  assetUrl: string;
}

export const FOR_RESTAURANT_CONTENT: InfoContent[] = [
  {
    title: 'Tailored experiences',
    description:
      'With our interactive guest-facing floor plan, craft unforgettable experiences by anticipating preferences every time. The result? Delighted diners, repeat visits, and increased restaurant revenue.',
    assetUrl: getS3AssetPath('landing-for-restaurants-1.png'),
  },
  {
    title: 'Offer a Reservation Marketplace',
    description:
      'Allow diners to resell reservations should their plans change, ensuring that your tables stay booked and that revenue flows.',
    assetUrl: getS3AssetPath('landing-for-restaurants-2.png'),
  },
  {
    title: 'Enjoy an All-In-One Service',
    description:
      'Combine table management and reservations in a single, free platform that’s one of a kind, eliminating the need for multiple subscriptions—we earn only when you earn on our platform. ',
    assetUrl: getS3AssetPath('landing-for-restaurants-3.png'),
  },
  {
    title: 'Cultivate Lasting Loyalty Among Customers',
    description:
      'Record detailed diner preferences such as seating preferences and dietary requirements right on our platform, so that you can build lasting, superior customer relationships. ',
    assetUrl: getS3AssetPath('landing-for-restaurants-4.png'),
  },
];

export const FOR_DINERS_CONTENT: InfoContent[] = [
  {
    title: 'Choose Your Table',
    description:
      'Pick your ideal table or area using our interactive floor plan, allowing you to visualize available spots and choose the one you like best.',
    assetUrl: getS3AssetPath('landing-for-diners-1.png'),
  },
  {
    title: 'Secure Your Reservation',
    description:
      'Buy sought-after reservations at the best available price, whether directly from the restaurant or diners whose plans have changed.',
    assetUrl: getS3AssetPath('landing-for-diners-2.png'),
  },
  {
    title: 'Sell Your Reservation if Plans Change',
    description:
      'Can’t make it? No problem—easily list and sell your reservation to other diners with our first-party marketplace, offering a convenient way for everyone to have access to the best seats in the house. ',
    assetUrl: getS3AssetPath('landing-for-diners-3.png'),
  },
];
