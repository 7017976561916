import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import type { TableIconCommonProps } from './InteractiveFloorPlanTable';
import styles from './NonInteractiveFloorPlan.scss';
import { calculateContainerStyle, calculateIconStyle } from './utils';

export interface NonInteractiveFloorPlanTableProps
  extends TableIconCommonProps {
  isHighlighted: boolean;
}

export const NonInteractiveFloorPlanTable = ({
  iconName,
  iconWidthScale,
  isHighlighted,
  left,
  orientation,
  tableIconScale,
  testId,
  top,
}: NonInteractiveFloorPlanTableProps) => {
  const transformedIconName = `${iconName}${
    isHighlighted ? 'Selected' : ''
  }` as AnyIcon;
  return (
    <div
      className={styles.container}
      data-testid={testId}
      style={calculateContainerStyle({
        left,
        tableIconScale,
        top,
      })}
    >
      <Icon
        name={transformedIconName}
        style={{
          ...calculateIconStyle({
            iconWidthScale,
            orientation,
            tableIconScale,
          }),
        }}
        testId={`icon-${transformedIconName}`}
      />
    </div>
  );
};
