import cx from 'classnames';
import Logo from '@assets/icons/logo.svg';
import typography from '~styles/typography.scss';
import styles from './LoadingPage.scss';
import { Spinner } from './Spinner';

export const LoadingPage = () => (
  <main className={styles.container} data-testid="loading-page">
    <div className={styles.content}>
      <div className={styles.spinnerContainer}>
        <Logo className={styles.logo} role="presentation" />
        <Spinner />
      </div>
      <h1 className={cx(typography.headingOne, styles.title)}>
        Peak Reservations
      </h1>
    </div>
  </main>
);
