// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".links__linkPrimary___O5hOY{color:var(--accent300);font-weight:700;font-size:12px;margin-top:8px}.links__linkPrimary___O5hOY:hover,.links__linkPrimary___O5hOY:focus{text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/shared/styles/links.scss"],"names":[],"mappings":"AAAA,4BACE,sBAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CAEA,oEAEE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkPrimary": "links__linkPrimary___O5hOY"
};
export default ___CSS_LOADER_EXPORT___;
