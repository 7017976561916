import PlatBackgroundDivider from '@assets/images/plate-background-divider.svg';
import { WideView } from '@components/wideView/WideView';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { Layout } from '../layout/Layout';
import { FinalCallToAction } from './FinalCallToAction';
import { Hero } from './Hero';
import { InfoTabsNarrow } from './InfoTabsNarrow';
import { InfoTabsWide } from './InfoTabsWide';
import styles from './LandingPage.scss';
import { Partners } from './Partners';

export const LandingPage = () => {
  useDocumentTitle('Peak Reservations');

  return (
    <Layout hideBorder>
      <main>
        <Hero />
        <WideView
          narrowElement={<InfoTabsNarrow />}
          wideElement={<InfoTabsWide />}
        />
        <PlatBackgroundDivider className={styles.divider} />
        <Partners />
        <FinalCallToAction />
        <hr className={styles.line} />
      </main>
    </Layout>
  );
};
