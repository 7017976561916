import cx from 'classnames';
import styles from './Error.scss';

interface ErrorProps {
  message?: string;
  className?: string;
}

export const Error = ({ message, className }: ErrorProps) =>
  message ? (
    <span className={cx(styles.container, className)}>{message}</span>
  ) : null;
