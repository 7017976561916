import type { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IPAD768 } from '@shared/styles/breakpoints';

type ComponentOrNull = ReactElement | null;

export interface WideViewProps {
  breakpoint?: number;
  narrowElement?: ComponentOrNull;
  wideElement?: ComponentOrNull;
}

export const WideView = ({
  breakpoint = IPAD768,
  narrowElement = null,
  wideElement = null,
}: WideViewProps): ComponentOrNull => {
  const isWideViewport = useMediaQuery({ minWidth: breakpoint });

  return isWideViewport ? wideElement : narrowElement;
};
