// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Unauthenticated__container___2ZX7Q{align-items:center;display:flex;flex-direction:column;justify-content:center;padding:40px;text-align:center}.Unauthenticated__title___k64XX{margin-top:128px;margin-bottom:0}@media only screen and (max-width: 720px){.Unauthenticated__title___k64XX{margin-top:64px}}.Unauthenticated__subtitle___YV1RF{margin-top:24px;margin-bottom:0;color:var(--white70)}.Unauthenticated__loginButton___TveRu{margin-top:40px;max-width:336px;width:100%}.Unauthenticated__signUpButton___k1Z3P{color:var(--white);margin-top:24px;max-width:336px;width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/components/Unauthenticated.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,oCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,YAAA,CACA,iBAAA,CAGF,gCACE,gBAAA,CACA,eAAA,CCEE,0CDJJ,gCAKI,eAAA,CAAA,CAIJ,mCACE,eAAA,CACA,eAAA,CACA,oBAAA,CAGF,sCACE,eAAA,CACA,eAAA,CACA,UAAA,CAGF,uCACE,kBAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Unauthenticated__container___2ZX7Q",
	"title": "Unauthenticated__title___k64XX",
	"subtitle": "Unauthenticated__subtitle___YV1RF",
	"loginButton": "Unauthenticated__loginButton___TveRu",
	"signUpButton": "Unauthenticated__signUpButton___k1Z3P"
};
export default ___CSS_LOADER_EXPORT___;
