import { isBefore } from 'date-fns';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateFormat } from '@shared/utils/date';
import { ISOTimeTo12HourTime } from '@shared/utils/time';
import typography from '~styles/typography.scss';
import {
  type CancellationPolicy,
  cancelReservation,
  type ReservationWithDetails,
} from './apiHelpers';
import styles from './GuestCancelConfirmationModal.scss';

export interface GuestCancelConfirmationModalProps {
  cancellationPolicy: CancellationPolicy | null;
  closeModal: () => void;
  isOpen: boolean;
  refreshReservation: () => void;
  reservation: ReservationWithDetails;
}

export const GuestCancelConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  isOpen,
  refreshReservation,
  reservation,
}: GuestCancelConfirmationModalProps) => {
  const handleOnClickCancel = () => {
    void (async () => {
      const response = await cancelReservation(reservation.id);

      if (response.ok) {
        successToast({ message: 'Successfully canceled your reservation.' });
      } else {
        errorToast({
          message: 'Sorry, there was an issue handling your request.',
        });
      }

      closeModal();
      refreshReservation();
    })();
  };

  const isCancellationPolicyApplicable =
    cancellationPolicy &&
    !isBefore(new Date(), new Date(cancellationPolicy.applicableAt));

  const renderFeeText = () => {
    if (!isCancellationPolicyApplicable) return null;

    return (
      <>
        <br />
        Canceling this reservation may incur a fee of{' '}
        {centsToDollar(cancellationPolicy.fee)}.
      </>
    );
  };

  return isOpen && reservation.restaurant.name ? (
    <Modal isOpen={isOpen} onClose={closeModal} title="Confirm Cancellation">
      <div className={styles.content}>
        <p className={typography.c2}>
          Are you sure you want to cancel your reservation?
        </p>

        <p className={typography.c2}>
          We understand that plans can change, but please note that{' '}
          <strong>canceling this reservation will not issue a refund.</strong>
          {renderFeeText()} This&nbsp;action is irreversible
          once&nbsp;confirmed.
        </p>

        <p className={typography.c2}>Your reservation details:</p>

        <dl>
          <div>
            <dt className={typography.h6}>Restaurant Name</dt>
            <dd className={typography.c2}>{reservation.restaurant.name}</dd>
          </div>
          <div>
            <dt className={typography.h6}>Reservation Date</dt>
            <dd className={typography.c2}>
              {toShortDateFormat(reservation.date)}
            </dd>
          </div>
          <div>
            <dt className={typography.h6}>Reservation Time</dt>
            <dd className={typography.c2}>
              {ISOTimeTo12HourTime(reservation.time)}
            </dd>
          </div>
          <div>
            <dt className={typography.h6}>Number of Guests</dt>
            <dd className={typography.c2}>{reservation.guestCount}</dd>
          </div>
        </dl>

        <p className={typography.c2}>
          By confirming this cancellation, you acknowledge that you will not
          receive a refund for this reservation. (
          <ExternalRefundLink className={styles.link} />)
        </p>
        <ModalActions className={styles.modalActions}>
          <Button
            label="Keep Reservation"
            onClick={closeModal}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Tertiary}
          />
          <Button
            label="Cancel Reservation"
            onClick={handleOnClickCancel}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </div>
    </Modal>
  ) : null;
};
