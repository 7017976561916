import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

export interface NotificationSetting {
  enabled: boolean;
  name: string;
}

interface SMSNotificationSettings {
  settings: NotificationSetting[];
}

export interface UserAccountData {
  balance: number;
  linkedAccountId: string;
}

export const getSMSNotificationSettings = async (): Promise<
  ApiResponse<SMSNotificationSettings>
> => {
  const response = await API.get(`/notification-settings`);
  return response.json();
};

export const updateSMSNotificationSettings = async (
  settings: SMSNotificationSettings,
): Promise<Response> => {
  const response = await API.put(`/notification-settings`, settings);
  return response;
};

export const getUserAccountData = async (): Promise<
  ApiResponse<UserAccountData>
> => {
  const response = await API.get(`/user/account`);
  return response.json();
};

export const transferBalance = async (): Promise<
  ApiResponse<Record<never, never>>
> => {
  const response = await API.post('/payouts/transfer');
  return response.json();
};

export const deleteBankAccount = async (): Promise<ApiResponse<any>> => {
  const response = await API.delete('/payouts/linked-account');
  return response.json();
};
