import cx from 'classnames';
import { uniqueId } from 'lodash-es';
import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import typography from '~styles/typography.scss';

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = ({
  checked = false,
  className,
  disabled = false,
  label,
  onChange,
}: CheckboxProps) => {
  const [id] = useState(uniqueId('check-box-'));

  return (
    <label className={cx(typography.c3, className)} htmlFor={id}>
      {label}
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
    </label>
  );
};
