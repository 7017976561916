// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner__spinner___nq2SC{border:2px solid var(--primary500);border-top:2px solid var(--accent300);border-radius:50%;width:10rem;height:10rem;animation:Spinner__spin___XDpBI 2s linear infinite}@keyframes Spinner__spin___XDpBI{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/shared/components/Spinner.scss"],"names":[],"mappings":"AAAA,0BACE,kCAAA,CACA,qCAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,kDAAA,CAGF,iCACE,GACE,sBAAA,CAGF,KACE,wBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "Spinner__spinner___nq2SC",
	"spin": "Spinner__spin___XDpBI"
};
export default ___CSS_LOADER_EXPORT___;
