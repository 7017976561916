// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantsPage__container___FPcDb{align-items:center;color:var(--white);display:flex;flex-direction:column;justify-content:center;margin:0 auto;max-width:28rem;padding:4rem 2rem;text-align:center;width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/RestaurantsPage.scss"],"names":[],"mappings":"AAAA,oCACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RestaurantsPage__container___FPcDb"
};
export default ___CSS_LOADER_EXPORT___;
