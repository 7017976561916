// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation__link___XHNs2{color:var(--white)}.Navigation__linkActive___kDrut{font-weight:600}.Navigation__badge___SY1G_{background-color:var(--lightGrey300);color:var(--black);align-items:center;border-radius:50%;border:2px solid var(--white);display:flex;font-weight:bold;height:40px;justify-content:center;white-space:nowrap;width:40px}.Navigation__container___SSK6u{display:flex;align-items:center}.Navigation__signUp___afkuV{color:#fff;display:flex;height:40px;align-items:center}", "",{"version":3,"sources":["webpack://./src/webReservations/navigation/Navigation.scss"],"names":[],"mappings":"AAAA,0BACE,kBAAA,CAGF,gCACE,eAAA,CAGF,2BACE,oCAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,YAAA,CACA,gBAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CAGF,+BACE,YAAA,CACA,kBAAA,CAGF,4BACE,UAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Navigation__link___XHNs2",
	"linkActive": "Navigation__linkActive___kDrut",
	"badge": "Navigation__badge___SY1G_",
	"container": "Navigation__container___SSK6u",
	"signUp": "Navigation__signUp___afkuV"
};
export default ___CSS_LOADER_EXPORT___;
