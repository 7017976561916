import { Icon } from '@components/icon/Icon';
import { formatPhone } from '@utils/formatPhone';
import typography from '~styles/typography.scss';
import styles from './RestaurantDetails.scss';

export interface RestaurantDetailsProps {
  restaurant: {
    address: string;
    id: string;
    name: string;
    phone: string;
    website: string;
  };
}

export const RestaurantDetails = ({ restaurant }: RestaurantDetailsProps) => (
  <section className={styles.container}>
    <h2 className={typography.h4}>Restaurant Details</h2>
    <a
      className={typography.t1}
      href={`https://maps.google.com/?q=${encodeURIComponent(
        restaurant.address,
      )}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>{restaurant.address}</span>
      <Icon name="link" />
    </a>
    <a
      className={typography.t1}
      href={restaurant.website}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>{restaurant.website.split('://')[1]}</span>
      <Icon name="link" />
    </a>
    <a
      className={typography.t1}
      href={`tel:${restaurant.phone}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>{formatPhone(restaurant.phone)}</span>
      <Icon name="link" />
    </a>
  </section>
);
